// Library imports
import React, { useState, useEffect } from 'react';
// Component imports
import CompareList from '../../../../../../components/CompareList';
import LoadingSpinner from '../../../../../../components/LoadingSpinner';
import TabNav from '../../../../../../components/TabNav';
// Util imports
import { shortName } from '../../../../../../utils';
import { keyFactorData } from './utils';
// Styled components
import { InnerContent } from '../styles';

const KeyFactors = ({ sport, game }) => {
    const [type, setType] = useState('offense');
    const [dataFields, setDataFields] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        async function fetchData() {
            try {
                setLoading(true);
                const factorType = type === 'offense' ? 'off' : 'def';
                const res = await keyFactorData(sport, game.awayTeam, game.homeTeam, factorType);
                setDataFields(res);
            } catch (error) {
                console.log(`Error getting key factor objects for ${sport}. ${error.message}`);
            } finally {
                setLoading(false);
            }
        }
        fetchData();
    }, [type]);

    return (
        <InnerContent fluid>
            <TabNav tabs={['offense', 'defense']} activeTab={type} setTab={setType} containerStyle={{ marginBottom: '1rem' }} />
            {loading ? (
                <LoadingSpinner />
            ) : (
                <CompareList
                    labels={dataFields[2]}
                    dataSetOneTitle={shortName(game.awayTeam)}
                    dataSetOneTitleColor={game.awayTeam.color}
                    dataSetOne={dataFields[0]}
                    dataSetTwoTitle={shortName(game.homeTeam)}
                    dataSetTwoTitleColor={game.homeTeam.color}
                    dataSetTwo={dataFields[1]}
                />
            )}
        </InnerContent>
    );
};

export default KeyFactors;
