// Library imports
import React from 'react';
import { MDBCol, MDBIcon, MDBInput } from 'mdb-react-ui-kit';
import { useTheme } from 'styled-components';
import { arrayOf, func, shape, string } from 'prop-types';
import moment from 'moment';
// Component imports
import Dropdown from '../DropDown';
// Styled components
import { SearchFilterContainer, SearchFilterRow, SearchWrapper } from './styles';

const AlgoSearchFilter = ({ gameData, setActiveDate, activeDate, value = '', setValue, filter, setFilter, timeframe, setTimeframe }) => {
    const theme = useTheme();

    return (
        <SearchFilterContainer fluid>
            <SearchFilterRow className="g-0">
                <MDBCol md={12} lg={2} className="first-col">
                    <Dropdown
                        icon={<MDBIcon icon="calendar" />}
                        caption="Timeframe"
                        toggleStyle={{
                            background: theme.colors.white,
                            borderRadius: 0,
                            borderTopLeftRadius: 999,
                            borderBottomLeftRadius: 999,
                        }}
                        value={timeframe}
                        setValue={selectedKey => setTimeframe(selectedKey)}
                        menuItems={['10 days', '30 days', '60 days', 'current_season']}
                    />
                </MDBCol>
                <MDBCol md={12} lg={6} className="search-col">
                    <SearchWrapper>
                        <MDBInput
                            type="search"
                            placeholder="Start typing team name..."
                            aria-label="Search"
                            id="card-search"
                            value={value}
                            onChange={e => setValue(e.target.value)}
                        />
                    </SearchWrapper>
                </MDBCol>
                <MDBCol md={12} lg={2} className=" last-col mb-1 mb-lg-0">
                    <Dropdown
                        icon={<MDBIcon icon="calendar" />}
                        caption="Game Date"
                        toggleStyle={{
                            background: theme.colors.white,
                            borderRadius: 0,
                            borderTopLeftRadius: 0,
                            borderBottomLeftRadius: 0,
                        }}
                        value={moment(activeDate).isValid() ? moment(activeDate).format('MM/DD/YYYY') : '---'}
                        setValue={selectedKey => setActiveDate(selectedKey)}
                        menuItems={[...new Set(gameData.map(item => moment(item.dateTime).format('MM/DD/YYYY')))].sort((a, b) => {
                            if (moment(a).isAfter(b)) return -1;
                            if (!moment(a).isBefore(b)) return 1;
                            if (moment(a).isSame(b)) return 1;
                        })}
                    />
                </MDBCol>
                <MDBCol md={12} lg={2} className="last-col">
                    <Dropdown
                        icon={<MDBIcon icon="filter" />}
                        caption="Filter"
                        toggleStyle={{
                            background: theme.colors.white,
                            borderRadius: 0,
                            borderTopRightRadius: 999,
                            borderBottomRightRadius: 999,
                        }}
                        value={filter}
                        setValue={selectedKey => {
                            if (selectedKey === 'Select...') {
                                setFilter('');
                            } else {
                                setFilter(selectedKey);
                            }
                        }}
                        menuItems={['Select...', 'No Score', 'No Prediction', 'No Odds']}
                    />
                </MDBCol>
            </SearchFilterRow>
        </SearchFilterContainer>
    );
};

AlgoSearchFilter.propTypes = {
    gameData: arrayOf(shape({})).isRequired,
    setActiveDate: func.isRequired,
    activeDate: string.isRequired,
    value: string,
    setValue: func.isRequired,
    filter: string.isRequired,
    setFilter: func.isRequired,
    filter: string.isRequired,
    setFilter: func.isRequired,
};

export default AlgoSearchFilter;
