// Library imports
import styled from 'styled-components';
import { MDBListGroupItem } from 'mdb-react-ui-kit';

export const RecordListItem = styled(MDBListGroupItem)`
    display: flex;
    flex-direction: row;
    gap: 0.25rem;
    justify-content: center;
    & label {
        font-family: ${({ theme }) => theme.fonts.heading};
        font-weight: 700;
    }
    & span {
        font-family: ${({ theme }) => theme.fonts.body};
        font-weight: 400;
    }
`;
