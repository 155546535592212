// Library imports
import React from 'react';
import { MDBIcon, MDBPagination, MDBPaginationItem, MDBPaginationLink } from 'mdb-react-ui-kit';
import { array, func, number, shape } from 'prop-types';

const Pagination = ({ items, currentPage, setCurrentPage, itemsPerPage, style }) => {
    const totalPages = Math.ceil(items ? items.length / itemsPerPage : 0);

    let startPage, endPage;
    if (totalPages <= 8) {
        startPage = 1;
        endPage = totalPages;
    } else {
        if (currentPage <= 4) {
            startPage = 1;
            endPage = 8;
        } else if (currentPage + 3 >= totalPages) {
            startPage = totalPages - 7;
            endPage = totalPages;
        } else {
            startPage = currentPage - 3;
            endPage = currentPage + 4;
        }
    }

    return (
        <MDBPagination style={{ ...style }}>
            <MDBPaginationItem disabled={currentPage === 1}>
                <MDBPaginationLink onClick={() => setCurrentPage(1)} first>
                    <MDBIcon icon="angle-double-left" />
                </MDBPaginationLink>
            </MDBPaginationItem>
            {Array.from({ length: endPage - startPage + 1 }, (_, index) => {
                const pageNumber = startPage + index;
                return (
                    <MDBPaginationItem key={index} active={pageNumber === currentPage}>
                        <MDBPaginationLink onClick={() => setCurrentPage(pageNumber)}>{pageNumber}</MDBPaginationLink>
                    </MDBPaginationItem>
                );
            })}
            <MDBPaginationItem disabled={currentPage === totalPages}>
                <MDBPaginationLink onClick={() => setCurrentPage(totalPages)} last>
                    <MDBIcon icon="angle-double-right" />
                </MDBPaginationLink>
            </MDBPaginationItem>
        </MDBPagination>
    );
};

Pagination.propTypes = {
    items: array.isRequired, // The list of items to paginate
    currentPage: number.isRequired, // The current page number
    setCurrentPage: func.isRequired, // Function to update the current page
    itemsPerPage: number.isRequired, // Number of items per page
    style: shape({}), // Optional style object for custom styling
};

export default Pagination;
