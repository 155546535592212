// Library imports
import React, { useEffect, useState } from 'react';
import { MDBBtn, MDBIcon, MDBInput } from 'mdb-react-ui-kit';
import { useDispatch, useSelector } from 'react-redux';
// Component imports
import AddFilters from './AddFilters';
import GameCard from '../GameCards/GameCard';
import Dropdown from '../../../components/DropDown';
import LoadingSpinner from '../../../components/LoadingSpinner';
import NoData from '../../../components/NoData';
import { queryFields } from './queryFields';
import Pagination from '../../../components/Pagination';
import Grid from '../../../components/Layouts/Grid';
import TabNav from '../../../components/TabNav';
import Alert from '../../../components/Alert';
// Styled components
import {
    SearchBuilderWrapper,
    SearchButtonContainer,
    SearchDrawer,
    SearchField,
    InputTitle,
    SearchResults,
    SearchFieldContainer,
    SearchResultsHeader,
    PaginationContainer,
} from './styles';
// Utils
import useScreenWidth from '../../../utils/hooks/useScreenWidth';
import { ADVANCED_SEARCH_SCREEN_SIZE } from '../../../utils/constants';
// Action imports
import { queryDatabase, updateData, resetData, setFocused, toggleField } from '../../../store/slices/queryBuilderSlice';

const SearchBuilder = () => {
    // Redux
    const dispatch = useDispatch();
    const { data, games, searchDrawerLoading, searchResultsLoading, error, focused, comingFromGameCard, activeFields } = useSelector(
        state => state.queryBuilder,
    );
    const { sport, teams, loaders } = useSelector(state => state.app);
    // Local state
    const [tab, setTab] = useState('builder');
    const windowWidth = useScreenWidth();
    // Pagination
    const [currentPage, setCurrentPage] = useState(1);
    const startIndex = (currentPage - 1) * 6;
    const endIndex = startIndex + 6;

    const getTeamData = (value, property) => {
        return teams.find(team => team[property] === value || team.city === value);
    };

    useEffect(() => {
        if (!comingFromGameCard) {
            dispatch(resetData());
        } else {
            dispatch(resetData('comingFromGameCard'));
        }
    }, [sport]);

    useEffect(() => {
        if (!data.awayTeam && data.awayOdds) {
            dispatch(updateData({ property: 'awayOdds', value: '' }));
        }
        if (!data.homeTeam && data.homeOdds) {
            dispatch(updateData({ property: 'homeOdds', value: '' }));
        }
    }, [data.awayTeam, data.homeTeam]);

    if (windowWidth < ADVANCED_SEARCH_SCREEN_SIZE) {
        return (
            <SearchBuilderWrapper>
                <NoData
                    title={'Please try again on a desktop.'}
                    message={'You must be on a desktop device to use this feature.'}
                    variant="danger"
                    containerStyle={{ margin: '25px auto', maxWidth: '90%' }}
                />
            </SearchBuilderWrapper>
        );
    }

    return (
        <SearchBuilderWrapper>
            <SearchDrawer id="search-drawer">
                <TabNav tabs={['builder', 'filters']} activeTab={tab} setTab={setTab} containerStyle={{ marginBottom: '1rem' }} />
                {tab === 'filters' ? (
                    <AddFilters />
                ) : searchDrawerLoading ? (
                    <LoadingSpinner />
                ) : (
                    <div style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
                        <SearchFieldContainer>
                            <MDBBtn
                                color="link"
                                id="home-away-switch"
                                style={{ position: 'absolute', right: -15, fontSize: 14 }}
                                onClick={() => {
                                    if (data.awayTeam && data.homeTeam) {
                                        dispatch(updateData({ property: 'awayTeam', value: data.homeTeam }));
                                        dispatch(updateData({ property: 'homeTeam', value: data.awayTeam }));
                                    }
                                }}
                            >
                                <MDBIcon icon="arrow-right-arrow-left" />
                                home/away
                            </MDBBtn>
                            <SearchField>
                                <InputTitle className="mt-2">Away Team</InputTitle>
                                <Dropdown
                                    variant="light"
                                    toggleStyle={{ border: '1px solid #ddd' }}
                                    value={getTeamData(data.awayTeam, 'teamId') ? getTeamData(data.awayTeam, 'teamId').teamName : ''}
                                    setValue={selectedKey =>
                                        dispatch(
                                            updateData({
                                                property: 'awayTeam',
                                                value: getTeamData(selectedKey, 'teamName') ? getTeamData(selectedKey, 'teamName').teamId : '',
                                            }),
                                        )
                                    }
                                    menuItems={teams.map(team => team.teamName)}
                                    searchable={true}
                                    clearable={true}
                                    disabled={loaders.find(loader => loader === 'team-data')}
                                />
                                <InputTitle className="mt-2">Away Odds</InputTitle>
                                <div style={{ display: 'flex' }}>
                                    <Dropdown
                                        dropdownStyle={{
                                            width: '55px',
                                            marginRight: '5px',
                                            border: '1px solid #ddd',
                                        }}
                                        variant={'light'}
                                        value={data.awayOddsSymbol}
                                        setValue={selectedKey =>
                                            dispatch(
                                                updateData({
                                                    property: 'awayOddsSymbol',
                                                    value: selectedKey,
                                                }),
                                            )
                                        }
                                        menuItems={['>', '<', '=']}
                                    />
                                    <MDBInput
                                        type="number"
                                        value={data.awayOdds}
                                        disabled={!data.awayTeam}
                                        onChange={e =>
                                            dispatch(
                                                updateData({
                                                    property: 'awayOdds',
                                                    value: e.target.value ? Number(e.target.value) : '',
                                                }),
                                            )
                                        }
                                    />
                                </div>
                            </SearchField>
                            <SearchField>
                                <InputTitle className="mt-2">Home Team</InputTitle>
                                <Dropdown
                                    variant={'light'}
                                    toggleStyle={{ border: '1px solid #ddd' }}
                                    value={getTeamData(data.homeTeam, 'teamId') ? getTeamData(data.homeTeam, 'teamId').teamName : ''}
                                    setValue={selectedKey =>
                                        dispatch(
                                            updateData({
                                                property: 'homeTeam',
                                                value: getTeamData(selectedKey, 'teamName') ? getTeamData(selectedKey, 'teamName').teamId : '',
                                            }),
                                        )
                                    }
                                    menuItems={teams.map(team => team.teamName)}
                                    searchable={true}
                                    clearable={true}
                                    disabled={loaders.find(loader => loader === 'team-data')}
                                />
                                <InputTitle className="mt-2">Home Odds</InputTitle>
                                <div style={{ display: 'flex' }}>
                                    <Dropdown
                                        dropdownStyle={{
                                            width: '55px',
                                            marginRight: '5px',
                                            border: '1px solid #ddd',
                                        }}
                                        variant={'light'}
                                        value={data.homeOddsSymbol}
                                        setValue={selectedKey =>
                                            dispatch(
                                                updateData({
                                                    property: 'homeOddsSymbol',
                                                    value: selectedKey,
                                                }),
                                            )
                                        }
                                        menuItems={['>', '<', '=']}
                                    />
                                    <MDBInput
                                        type="number"
                                        value={data.homeOdds}
                                        disabled={!data.homeTeam}
                                        onChange={e =>
                                            dispatch(
                                                updateData({
                                                    property: 'homeOdds',
                                                    value: e.target.value ? Number(e.target.value) : '',
                                                }),
                                            )
                                        }
                                    />
                                </div>
                            </SearchField>
                            {activeFields.map(field => {
                                const fieldData = queryFields[field];
                                return (
                                    <SearchField>
                                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                            <InputTitle className="mt-2">{fieldData.title}</InputTitle>
                                            <MDBBtn color="link" style={{ fontSize: '12px' }} onClick={() => dispatch(toggleField(fieldData.id))}>
                                                Remove Field
                                            </MDBBtn>
                                        </div>
                                        {fieldData.formType === 'number' ? (
                                            <div style={{ display: 'flex' }}>
                                                <Dropdown
                                                    dropdownStyle={{ width: '55px', marginRight: '5px', border: '1px solid #ddd' }}
                                                    variant="light"
                                                    value={data[`${fieldData.id}Symbol`]}
                                                    setValue={selectedKey =>
                                                        dispatch(
                                                            updateData({
                                                                property: `${fieldData.id}Symbol`,
                                                                value: selectedKey,
                                                            }),
                                                        )
                                                    }
                                                    menuItems={['>', '<', '=']}
                                                />
                                                <MDBInput
                                                    type="number"
                                                    value={data[fieldData.id]}
                                                    onChange={e =>
                                                        dispatch(updateData({ property: fieldData.id, value: e.target.value ? Number(e.target.value) : '' }))
                                                    }
                                                />
                                            </div>
                                        ) : (
                                            <></>
                                        )}
                                    </SearchField>
                                );
                            })}
                        </SearchFieldContainer>
                        <SearchButtonContainer>
                            <MDBBtn color="outline-danger" style={{ marginRight: '10px' }} onClick={() => dispatch(resetData())}>
                                Reset
                            </MDBBtn>
                            <MDBBtn onClick={() => dispatch(queryDatabase(sport))}>Run Query</MDBBtn>
                        </SearchButtonContainer>
                    </div>
                )}
            </SearchDrawer>
            <SearchResults>
                {focused.teamName ? (
                    <SearchResultsHeader>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <Dropdown
                                variant={'light'}
                                dropdownStyle={{
                                    width: 'auto',
                                    marginRight: '15px',
                                }}
                                toggleStyle={{ border: '1px solid #ddd' }}
                                value={getTeamData(focused.teamName, 'teamId').teamName}
                                setValue={selectedKey => dispatch(setFocused(getTeamData(selectedKey, 'teamName').teamId))}
                                menuItems={[data.awayTeam, data.homeTeam].filter(item => !!item).map(item => getTeamData(item, 'teamId').teamName)}
                            />
                            <div style={{ display: 'flex' }}>
                                <div style={{ marginRight: '15px' }}>
                                    <span style={{ marginRight: '5px' }}>Record:</span>
                                    <span>{`${focused.wins}-${focused.losses}`}</span>
                                </div>
                                <div style={{ marginRight: '15px' }}>
                                    <span style={{ marginRight: '5px' }}>ATS:</span>
                                    <span>{`${focused.atsWins}-${focused.atsLosses}`}</span>
                                </div>
                                <div style={{ marginRight: '15px' }}>
                                    <span style={{ marginRight: '5px' }}>Algo Record (ML):</span>
                                    <span>{`${focused.algoPowerlineWins}-${focused.algoPowerlineLosses}`}</span>
                                </div>
                                <div style={{ marginRight: '15px' }}>
                                    <span style={{ marginRight: '5px' }}>Algo Record (Spread):</span>
                                    <span>{`${focused.algoSpreadWins}-${focused.algoSpreadLosses}`}</span>
                                </div>
                            </div>
                        </div>
                        <div style={{ marginLeft: 'auto' }}>
                            <span>Results: {games.length}</span>
                        </div>
                    </SearchResultsHeader>
                ) : (
                    <></>
                )}
                <Grid>
                    {!games?.length ? (
                        error ? (
                            <Alert style={{ margin: '25% auto', width: '35rem' }} message={error} variant="danger" />
                        ) : searchResultsLoading ? (
                            <LoadingSpinner />
                        ) : data.awayTeam || data.homeTeam ? (
                            <Alert style={{ margin: '25% auto', width: '35rem' }} message='Click "Run Query" to view results' variant="success" />
                        ) : (
                            <Alert style={{ margin: '25% auto', width: '35rem' }} message="Build your query to get started." variant="light" />
                        )
                    ) : (
                        <>
                            <PaginationContainer>
                                <Pagination items={games} currentPage={currentPage} setCurrentPage={setCurrentPage} itemsPerPage={6} />
                            </PaginationContainer>
                            {games.slice(startIndex, endIndex).map(game => {
                                return <GameCard game={game} key={game.gameId} sport={sport} history={true} />;
                            })}
                        </>
                    )}
                </Grid>
            </SearchResults>
        </SearchBuilderWrapper>
    );
};

export default SearchBuilder;
