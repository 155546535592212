// Library imports
import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { MDBBtn, MDBCard, MDBCardBody } from 'mdb-react-ui-kit';
import { useDispatch, useSelector } from 'react-redux';
// Component imports
import Dropdown from '../../components/DropDown';
import TextInput from '../../components/InputLibrary/TextInput';
// Util imports
import { createPickId, convertToISOString } from '../../utils';
import { PICK_OBJ } from '../../utils/constants';
// Action imports
import { setUser } from '../../store/slices/userSlice';
// API imports
import { addPick } from '../../api/picks';
// Styled components
import { ProfileContentContainer } from './styles';

const CreatePick = () => {
    const dispatch = useDispatch();
    const { user } = useSelector(state => state.user);
    const {
        control,
        handleSubmit,
        reset,
        watch,
        setValue,
        formState: { isSubmitting },
    } = useForm({ defaultValues: { ...PICK_OBJ, sport: 'mlb', pickType: 'spread' } });

    const pickType = watch('pickType');
    const pickJuice = watch('pickJuice');
    const pickValue = watch('pickValue');
    const sport = watch('sport');

    useEffect(() => {
        if (pickType === 'moneyline') {
            setValue('pickJuice', pickValue);
        } else if (pickType === 'prop') {
            setValue('pickValue', pickJuice);
        }
    }, [pickType, pickJuice, pickValue]);

    const onSubmit = async data => {
        try {
            const pickData = {
                ...data,
                gameTime: convertToISOString(data.gameTime),
                gameId: '123',
                pickTeamId: 123,
                userId: user.uid,
                pickId: createPickId(),
            };
            const newPicks = await addPick(pickData);
            dispatch(setUser({ ...user, picks: newPicks }));
            reset();
        } catch (error) {
            console.log(`Error saving pick.\n${error}`);
        }
    };

    return (
        <ProfileContentContainer>
            <MDBCard>
                <MDBCardBody>
                    <form onSubmit={handleSubmit(onSubmit)} style={{ maxWidth: 800 }}>
                        <Dropdown
                            label="Sport"
                            value={sport}
                            setValue={value => setValue('sport', value)}
                            menuItems={['mlb', 'nhl', 'cfb', 'nfl', 'nba', 'cbb', 'ncaa-baseball']}
                            dropdownStyle={{ marginBottom: '1rem' }}
                        />
                        <Dropdown
                            label="Type"
                            value={pickType}
                            setValue={value => setValue('pickType', value)}
                            menuItems={['spread', 'moneyline', 'over', 'under', 'parlay', 'teaser', 'prop']}
                            dropdownStyle={{ marginBottom: '1rem' }}
                        />
                        <TextInput name="teamName" control={control} label="Team / Player Name" placeholder="Enter a team or player name..." isRequired />
                        <TextInput
                            name="pickValue"
                            control={control}
                            label="Value"
                            type="number"
                            disabled={pickType === 'prop'}
                            isRequired
                            rules={{ valueAsNumber: true }}
                        />
                        <TextInput
                            name="pickJuice"
                            control={control}
                            label="Juice"
                            type="number"
                            disabled={pickType === 'moneyline'}
                            isRequired
                            rules={{ valueAsNumber: true }}
                        />
                        <TextInput name="units" control={control} label="Units" type="number" isRequired rules={{ valueAsNumber: true }} />
                        <TextInput name="analysis" control={control} label="Analysis" placeholder="Enter a play analysis..." maxLength={75} />
                        <TextInput name="gameTime" control={control} label="Game Date" type="datetime-local" isRequired />
                        <MDBBtn type="submit" disabled={isSubmitting} color="success">
                            Save Pick
                        </MDBBtn>
                    </form>
                </MDBCardBody>
            </MDBCard>
        </ProfileContentContainer>
    );
};

export default CreatePick;
