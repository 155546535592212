// Library imports
import styled from 'styled-components';

export const StyledIcon = styled.span`
    color: ${({ theme }) => theme.colors.primary};
`;

export const KeyContainer = styled.div`
    margin-top: 15px;
    span {
        color: ${({ theme }) => theme.colors.black};
    }
`;

export const KeyItemContainer = styled.div`
    display: flex;
    flex-direction: row;
    margin-top: 5px;
    align-items: center;
    gap: 5px;
    span {
        color: ${({ theme }) => theme.colors.black};
        font-size: 14px;
    }
`;
