// Libraries imports
import React, { useState } from 'react';
// Component imports
import Insights from './Insights';
import Weekly from './Weekly';
import TabNav from '../../../../../components/TabNav';
// Styled Components
import { DrawerContent } from '../../styles';

const VIPDrawer = () => {
    // Local state
    const [tab, setTab] = useState('weekly');

    return (
        <DrawerContent fluid>
            <TabNav tabs={['weekly', 'insights']} activeTab={tab} setTab={setTab} containerStyle={{ marginBottom: '1rem' }} />
            {tab === 'weekly' ? <Weekly /> : <Insights />}
        </DrawerContent>
    );
};

export default VIPDrawer;
