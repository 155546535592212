// Library imports
import styled from 'styled-components';
import { MDBTabs, MDBTabsLink } from 'mdb-react-ui-kit';

export const StyledTabs = styled(MDBTabs)`
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    flex-direction: row;
    gap: 0.5rem;
`;

export const StyledTabsLink = styled(MDBTabsLink)`
    transition: all 0.3s ease;
    display: flex;
    align-items: center;
    margin: 0 !important;
    padding: 1rem 1.25rem !important;
    font-size: 0.85rem !important;

    &:hover {
        background-color: ${({ theme }) => theme.colors.primary} !important;
        color: ${({ theme }) => theme.colors.white} !important;
        cursor: pointer;
    }
`;
