// Library imports
import React from 'react';
import { MDBCard, MDBListGroupItem } from 'mdb-react-ui-kit';
// Component imports
import TwoColumnView from '../../../../../../components/TwoColumnView';
// Controller imports
import { formatOdd, shortName } from '../../../../../../utils';
// Styled components
import { CardTitle, InnerContent } from '../styles';
import { LabelValueListGroup } from '../../../../styles';
import { PowerMetric, PowerMetrics, ScoreText } from './styles';

const Prediction = ({ game }) => {
    const addZeroes = num1 => {
        var num = Number(num1);
        if (isNaN(num)) return 0;
        if (String(num).split('.').length < 2 || String(num).split('.')[1].length <= 2) {
            num = num.toFixed(2);
        }
        return num;
    };

    const renderPrediction = team => {
        const side = team.teamId === game.awayTeam.teamId ? 'away' : 'home';
        const score = side === 'away' ? game.prediction.awayScore : game.prediction.homeScore;
        var powerline = 0;
        if (side === 'away') {
            powerline = game.prediction.awayScore > game.prediction.homeScore ? game.prediction.powerMoneyline.favML : game.prediction.powerMoneyline.dogML;
        } else {
            powerline = game.prediction.homeScore > game.prediction.awayScore ? game.prediction.powerMoneyline.favML : game.prediction.powerMoneyline.dogML;
        }
        powerline = formatOdd(powerline, 'moneyline');
        return (
            <MDBCard>
                <CardTitle className="text-center" color={team.color} style={{ display: 'block' }}>
                    {shortName(team)}
                </CardTitle>
                <ScoreText color={team.color}>{addZeroes(score)}</ScoreText>
                <PowerMetrics>
                    <PowerMetric>
                        <label>Powerline:</label> <span>{powerline}</span>
                    </PowerMetric>
                </PowerMetrics>
                <LabelValueListGroup>
                    {game.advantages.map((adv, i) => {
                        if (adv.teamId === team.teamId) {
                            return (
                                <MDBListGroupItem key={i}>
                                    <label>{adv.adv}</label>
                                    <span>{adv.description}</span>
                                </MDBListGroupItem>
                            );
                        }
                        return false;
                    })}
                </LabelValueListGroup>
            </MDBCard>
        );
    };

    return (
        <InnerContent fluid>
            <TwoColumnView columnOneJSX={renderPrediction(game.awayTeam)} columnTwoJSX={renderPrediction(game.homeTeam)} />
        </InnerContent>
    );
};

export default Prediction;
