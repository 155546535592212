import styled from 'styled-components';

export const ButtonContainer = styled.div`
    display: flex;
    align-items: center;
    gap: 1rem;
    margin-top: 1rem;
`;

export const QuickActionsWrapper = styled.div`
    display: flex;
    flex-direction: column;
`;

export const QuickActionItem = styled.div`
    display: flex;
    flex-direction: column;
    padding-bottom: 0.5rem;
    border-bottom: 1px solid #ccc;
    margin-bottom: 1rem;

    h6 {
        margin-bottom: 0.5rem;
        font-size: 1rem;
    }
`;

export const CheckboxWrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 0.5rem;
`;
