// Library imports
import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { MDBTable, MDBTableHead, MDBTableBody, MDBCardBody } from 'mdb-react-ui-kit';
// Component imports
import LoadingSpinner from '../../../../../components/LoadingSpinner';
// Action imports
import { fetchLeague } from '../../../../../store/slices/appSlice';
// Styled components
import { ConferenceWrapper, DivisionCard, DivisionWrapper, StandingsTitle } from './styles';
import { CardHeader } from '../VIPDrawer/Weekly/styles';
import { DrawerContent } from '../../styles';
// Util imports
import { shortName } from '../../../../../utils';

const StandingsDrawer = () => {
    // Redux
    const { loaders, sport, leagueData, teams: reduxTeams } = useSelector(state => state.app);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(fetchLeague());
    }, []);

    const renderStandings = () => {
        var html = [];
        if (!leagueData?.standings) return html;
        if (sport === 'mlb' || sport === 'nfl' || sport === 'nba' || sport === 'nhl') {
            const conferences = Object.keys(leagueData.standings);
            conferences.forEach(conference => {
                const conferenceData = leagueData.standings[conference];
                const divisions = Object.keys(conferenceData);
                const divisionHtml = [];
                divisions.forEach(division => {
                    const teams = conferenceData[division];
                    if (!Array.isArray(teams)) return;
                    const tableData = teams.map((team, i) => {
                        return {
                            rank: i + 1,
                            team: shortName(team),
                            record: `${team.wins}-${team.losses}`,
                            gamesBack: team.gb,
                            imgSrc: team?.logoUrl || '',
                        };
                    });
                    divisionHtml.push(
                        <DivisionCard>
                            <CardHeader>{division}</CardHeader>
                            <MDBCardBody>
                                <MDBTable striped responsive bordered>
                                    <MDBTableHead dark>
                                        <tr>
                                            <th>Rank</th>
                                            <th>Team</th>
                                            <th>Record</th>
                                            <th>GB</th>
                                        </tr>
                                    </MDBTableHead>
                                    <MDBTableBody>
                                        {tableData.map(({ rank, team, record, gamesBack, imgSrc }) => (
                                            <tr key={rank}>
                                                <td>{rank}</td>
                                                <td>
                                                    {imgSrc && <img src={imgSrc} alt={team} style={{ width: 30, height: 30, marginRight: 8 }} />}
                                                    {team}
                                                </td>
                                                <td>{record}</td>
                                                <td>{gamesBack}</td>
                                            </tr>
                                        ))}
                                    </MDBTableBody>
                                </MDBTable>
                            </MDBCardBody>
                        </DivisionCard>,
                    );
                });
                html.push(
                    <ConferenceWrapper>
                        <StandingsTitle>{conference}</StandingsTitle>
                        <DivisionWrapper>{divisionHtml}</DivisionWrapper>
                    </ConferenceWrapper>,
                );
            });
        }

        if (sport === 'cfb') {
            if (Array.isArray(leagueData.standings)) {
                const tableData = leagueData.standings.map((team, i) => {
                    const teamData = reduxTeams.find(reduxTeam => reduxTeam.teamId === team.teamId);
                    return {
                        rank: i + 1,
                        team: team.teamName,
                        record: team.record,
                        imgSrc: teamData?.logoUrl || '',
                    };
                });
                html.push(
                    // TODO test this next CFB season I was not able to test this when implemented
                    <ConferenceWrapper>
                        <StandingsTitle>AP Top 25</StandingsTitle>
                        <MDBTable striped responsive bordered>
                            <MDBTableHead dark>
                                <tr>
                                    <th>Rank</th>
                                    <th>Team</th>
                                    <th>Record</th>
                                </tr>
                            </MDBTableHead>
                            <MDBTableBody>
                                {tableData.map(({ rank, team, record, imgSrc }) => (
                                    <tr key={rank}>
                                        <td>{rank}</td>
                                        <td>
                                            {imgSrc && <img src={imgSrc} alt={team} style={{ width: 30, height: 30, marginRight: 8 }} />}
                                            {team}
                                        </td>
                                        <td>{record}</td>
                                    </tr>
                                ))}
                            </MDBTableBody>
                        </MDBTable>
                    </ConferenceWrapper>,
                );
            }
        }

        return html;
    };

    if (loaders.find(loader => loader === 'league-data')) {
        return (
            <div>
                <LoadingSpinner />
            </div>
        );
    }

    return <DrawerContent fluid>{renderStandings()}</DrawerContent>;
};

export default StandingsDrawer;
