// Library imports
import React, { useState, useEffect } from 'react';
import { MDBCard, MDBCardBody, MDBListGroup } from 'mdb-react-ui-kit';
import moment from 'moment';
// Component imports
import LoadingSpinner from '../../../../../../components/LoadingSpinner';
import BarChart from '../../../../../../components/Graphs/BarChart';
import SportsNav from '../../../../../../components/SportsNav';
// Styled components
import { CardHeader } from '../Weekly/styles';
import { RecordListItem } from './styles';
// API imports
import { vipPickRecordYTD } from '../../../../../../api/picks';

const Insights = () => {
    // Local state
    const [loading, setLoading] = useState(true);
    const [ytdData, setYTDData] = useState({});
    const [activeSport, setActiveSport] = useState('all');

    const getCalendarData = async () => {
        try {
            setLoading(true);
            const res = await vipPickRecordYTD();
            setYTDData(res);
        } catch (error) {
            console.log(`Error getting calendar data.\n${error.message}`);
        } finally {
            setLoading(false);
        }
    };

    const formatYTDData = () => {
        return Object.keys(ytdData).map(month => {
            return {
                Month: month,
                Units: ytdData[month][activeSport].units,
            };
        });
    };

    const formatUnitTotal = () => {
        const total = Object.keys(ytdData).reduce((acc, month, i) => {
            return acc + ytdData[month][activeSport].units;
        }, 0);
        return total.toFixed(2);
    };

    const formatRecordTotal = () => {
        const wins = Object.keys(ytdData).reduce((acc, month, i) => {
            return acc + ytdData[month][activeSport].wins;
        }, 0);
        const losses = Object.keys(ytdData).reduce((acc, month, i) => {
            return acc + ytdData[month][activeSport].losses;
        }, 0);
        return `${wins}-${losses}`;
    };

    useEffect(() => {
        getCalendarData();
    }, []);

    return (
        <MDBCard>
            <CardHeader>{moment().format('YYYY')} YTD Record</CardHeader>
            <MDBCardBody>
                {loading ? (
                    <LoadingSpinner />
                ) : (
                    <div>
                        <SportsNav all={true} click={setActiveSport} activeItem={activeSport} />
                        <MDBListGroup flush className="mb-3" horizontal={true} style={{ maxWidth: '35rem', margin: '0 auto' }}>
                            <RecordListItem className="flex-fill ">
                                <label>Record:</label>
                                <span>{formatRecordTotal()}</span>
                            </RecordListItem>
                            <RecordListItem className="flex-fill ">
                                <label>Units:</label>
                                <span>{formatUnitTotal()}u</span>
                            </RecordListItem>
                        </MDBListGroup>
                        <BarChart xAxisLabel="Month" yAxisLabel="Units" yAxisKey="Units" data={formatYTDData()} symbol="u" fixedAmount={1} />
                    </div>
                )}
            </MDBCardBody>
        </MDBCard>
    );
};

export default Insights;
