// Library imports
import React, { useEffect, useState } from 'react';
import { MDBBtn, MDBCard, MDBCardBody, MDBCardSubTitle, MDBCheckbox, MDBCol, MDBContainer, MDBIcon, MDBInput, MDBRow } from 'mdb-react-ui-kit';
import { JsonEditor } from 'json-edit-react';
import { useTheme } from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
// Style imports
import { CardHeader } from '../../Sidebar/Drawer/drawers/VIPDrawer/Weekly/styles';
import { ButtonContainer, CheckboxWrapper, QuickActionItem, QuickActionsWrapper } from './styles';
// API imports
import { getGame, saveGame } from '../../../api/games';
// Action imports
import { fetchGames, setSubPage } from '../../../store/slices/appSlice';
// Firebase imports
import firebase from 'firebase';

const EditGame = () => {
    const theme = useTheme();
    // Redux
    const { sport, editGame, gameData } = useSelector(state => state.app);
    const dispatch = useDispatch();
    // Local state
    const [data, setData] = useState({});
    const [loading, setLoading] = useState(false);

    const loadGame = async db => {
        try {
            setLoading(true);
            const res = await getGame(sport, editGame.id, db);
            setData(res);
        } catch (error) {
            console.log(`Error fetching game: ${error.message}`);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        if (editGame.id) {
            loadGame(editGame.db);
        }
    }, [editGame]);

    const removeGameFromFirebase = async () => {
        const newData = gameData.filter(g => g.gameId !== data.gameId);
        await firebase.database().ref('line-prophet').child(`${sport.toUpperCase()}/game-info/`).set(newData);
        dispatch(fetchGames());
    };

    // NOTE DB is hardcoded to mongo right now in function call
    const handleSave = async () => {
        try {
            setLoading(true);
            if (!data.winner || !data.spreadWinner) {
                return alert('Please fill out winner and spreadWinner');
            }
            if (!data.awayTeam.score) {
                return alert('Please fill out awayTeam score');
            }
            if (!data.homeTeam.score) {
                return alert('Please fill out homeTeam score');
            }
            await saveGame(data, sport, 'mongo');
            await removeGameFromFirebase();
            loadGame('mongo');
        } catch (error) {
            console.log(`Error saving game\n${error.message}`);
        } finally {
            setLoading(false);
        }
    };

    const handleDelete = async () => {
        try {
            setLoading(true);
            if (editGame.db === 'firebase') {
                await removeGameFromFirebase();
                dispatch(setSubPage('games'));
            } else {
                alert('Cannot delete games from mongo');
            }
        } catch (error) {
            console.log(`Error deleting game\n${error}`);
        } finally {
            setLoading(false);
        }
    };

    return (
        <MDBContainer style={{ backgroundColor: theme.colors.silver, padding: '1rem' }} fluid>
            <MDBRow>
                <MDBCol md="12" lg="4">
                    <MDBCard>
                        <CardHeader style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                            Save Games
                            <div style={{ display: 'flex', alignItems: 'center', gap: '0.5rem' }}>
                                <MDBBtn color="danger" disabled={loading} onClick={handleDelete}>
                                    <MDBIcon icon="trash" />
                                </MDBBtn>
                                <MDBBtn
                                    color="secondary"
                                    disabled={loading}
                                    onClick={() => {
                                        loadGame(editGame.db);
                                    }}
                                >
                                    <MDBIcon icon="refresh" />
                                </MDBBtn>
                            </div>
                        </CardHeader>
                        <MDBCardBody>
                            <QuickActionsWrapper>
                                <QuickActionItem style={{ gap: '0.5rem' }}>
                                    <h6>Score</h6>
                                    <MDBInput
                                        label={data.awayTeam?.teamName}
                                        onChange={e => setData({ ...data, awayTeam: { ...data.awayTeam, score: Number(e.target.value) } })}
                                        value={data.awayTeam?.score !== undefined ? data.awayTeam?.score : ''}
                                        type="number"
                                    />
                                    <MDBInput
                                        label={data.homeTeam?.teamName}
                                        onChange={e => setData({ ...data, homeTeam: { ...data.homeTeam, score: Number(e.target.value) } })}
                                        value={data.homeTeam?.score !== undefined ? data.homeTeam?.score : ''}
                                        type="number"
                                    />
                                </QuickActionItem>
                                <QuickActionItem>
                                    <h6>Winner</h6>
                                    <CheckboxWrapper>
                                        <MDBCheckbox
                                            name="checkNoLabel"
                                            id="checkboxNoLabel"
                                            onChange={() => setData({ ...data, winner: data.awayTeam?.teamId })}
                                            checked={data.winner === data.awayTeam?.teamId}
                                            disabled={loading}
                                        />
                                        <label>{data.awayTeam?.teamName}</label>
                                    </CheckboxWrapper>
                                    <CheckboxWrapper>
                                        <MDBCheckbox
                                            name="checkNoLabel"
                                            id="checkboxNoLabel"
                                            onChange={() => setData({ ...data, winner: data.homeTeam?.teamId })}
                                            checked={data.winner === data.homeTeam?.teamId}
                                            disabled={loading}
                                        />
                                        <label>{data.homeTeam?.teamName}</label>
                                    </CheckboxWrapper>
                                </QuickActionItem>
                                <QuickActionItem>
                                    <h6>Spread Winner</h6>
                                    <CheckboxWrapper>
                                        <MDBCheckbox
                                            name="checkNoLabel"
                                            id="checkboxNoLabel"
                                            onChange={() => setData({ ...data, spreadWinner: data.awayTeam?.teamId })}
                                            checked={data.spreadWinner === data.awayTeam?.teamId}
                                            disabled={loading}
                                        />
                                        <label>{data.awayTeam?.teamName}</label>
                                    </CheckboxWrapper>
                                    <CheckboxWrapper>
                                        <MDBCheckbox
                                            name="checkNoLabel"
                                            id="checkboxNoLabel"
                                            onChange={() => setData({ ...data, spreadWinner: data.homeTeam?.teamId })}
                                            checked={data.spreadWinner === data.homeTeam?.teamId}
                                            disabled={loading}
                                        />
                                        <label>{data.homeTeam?.teamName}</label>
                                    </CheckboxWrapper>
                                </QuickActionItem>
                            </QuickActionsWrapper>
                            <ButtonContainer>
                                <MDBBtn onClick={() => handleSave()} color="primary">
                                    Save Mongo
                                </MDBBtn>
                            </ButtonContainer>
                        </MDBCardBody>
                    </MDBCard>
                </MDBCol>
                <MDBCol md="12" lg="8">
                    <MDBCard>
                        <CardHeader>
                            {data.awayTeam?.teamName} vs. {data.homeTeam?.teamName}
                            <MDBCardSubTitle style={{ fontSize: '1rem' }}>{moment(new Date(data.dateTime)).format('MM/DD h:mm A')}</MDBCardSubTitle>
                        </CardHeader>
                        <MDBCardBody>
                            <JsonEditor data={data} setData={setData} maxWidth="100%" collapse={1} />
                        </MDBCardBody>
                    </MDBCard>
                </MDBCol>
            </MDBRow>
        </MDBContainer>
    );
};

export default EditGame;
