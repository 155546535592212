// Library imports
import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';
// Component imports
import Grid from '../../../components/Layouts/Grid';
import GameCard from '../GameCards/GameCard';
import NoData from '../../../components/NoData';
import AlgoSearchFilter from '../../../components/SearchFilters/AlgoSearchFilter';
import LoadingSpinner from '../../../components/LoadingSpinner';
// Util imports
import { filterGames } from '../GameCards/utils';
import { sortGames } from '../../../utils';
// API imports
import { getCompletedGames } from '../../../api/games';

Array.prototype.hasMax = function (attrib) {
    return (
        (this.length &&
            this.reduce(function (prev, curr) {
                return prev[attrib] > curr[attrib] ? prev : curr;
            })) ||
        null
    );
};

const HistoryCards = () => {
    // Redux
    const { sport } = useSelector(state => state.app);
    // Local state
    const [gameData, setGameData] = useState([]);
    const [value, setValue] = useState('');
    const [filter, setFilter] = useState('');
    const [timeframe, setTimeframe] = useState('10 days');
    const [activeDate, setActiveDate] = useState(moment().toISOString());
    const [loading, setLoading] = useState(true);

    const fetchCompletedGames = async () => {
        try {
            setLoading(true);
            const formattedTimeframe = timeframe === 'current_season' ? 'current_season' : Number(timeframe.split(' ')[0]);
            const data = await getCompletedGames(sport, formattedTimeframe, filter);
            const sortedData = sortGames(data);
            setGameData(sortedData);
        } catch (error) {
            console.log(`Error getting algo stats data\n${error.message}`);
        } finally {
            setLoading(false);
        }
    };
    useEffect(() => {
        fetchCompletedGames();
    }, [timeframe, sport, filter]);

    useEffect(() => {
        if (!gameData.length) {
            setActiveDate(moment().toISOString());
        } else {
            setActiveDate(gameData.hasMax('dateTime').dateTime);
        }
    }, [gameData]);

    const processGameCards = () => {
        // NOTE filters in the history game cards are handeled via BE query
        const filteredGames = filterGames(gameData, value, [], activeDate);
        if (filteredGames.length) {
            const gameCards = filteredGames.map(game => {
                return <GameCard game={game} key={game.gameId} sport={sport} history={true} />;
            });
            return gameCards;
        } else {
            return <NoData />;
        }
    };

    return (
        <div>
            <AlgoSearchFilter
                gameData={gameData}
                activeDate={activeDate}
                setActiveDate={setActiveDate}
                value={value}
                setValue={setValue}
                filter={filter}
                setFilter={setFilter}
                timeframe={timeframe}
                setTimeframe={setTimeframe}
            />
            <Grid>{loading ? <LoadingSpinner /> : processGameCards(gameData)}</Grid>
        </div>
    );
};

export default HistoryCards;
