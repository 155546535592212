// Library and Component imports
import React, { useState } from 'react';
import ReactJson from 'react-json-view';
import { MDBBtn, MDBCol, MDBRow } from 'mdb-react-ui-kit';
// Component imports
import LoadingSpinner from '../../components/LoadingSpinner';
import Alert from '../../components/Alert';
// Util imports
import { ACTIVE_SPORTS } from '../../utils/constants';
// Styled components
import { ProfileContentContainer } from './styles';
// API imports
import { updateGames } from '../../api/games';
import { updateTeams } from '../../api/teams';
import { updatePropData } from '../../api/props';
import { updateLeagueData } from '../../api/leagues';

const APIAdmin = () => {
    const [data, setData] = useState({});
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);

    const allData = async (type = 'all') => {
        try {
            setLoading(true);
            for (let sport of ACTIVE_SPORTS) {
                if (type === 'games') {
                    await updateGames(sport);
                    continue;
                }
                if (type === 'teams') {
                    await updateTeams(sport);
                    continue;
                }
                if (type === 'props') {
                    await updatePropData(sport);
                    continue;
                }
                if (type === 'leagues') {
                    await updateLeagueData(sport);
                    continue;
                }
                await updateLeagueData(sport);
                await updateTeams(sport);
                await updateGames(sport);
            }
            setError('');
        } catch (error) {
            console.log(error.message);
            setError('Error updating matchup data');
        } finally {
            setData([]);
            setLoading(false);
        }
    };

    const gameData = async e => {
        var sport = e.currentTarget.id;
        try {
            setLoading(true);
            const res = await updateGames(sport);
            setData(res);
            setError('');
        } catch (error) {
            console.log(error.message);
            setError('Error updating matchup data');
        } finally {
            setLoading(false);
        }
    };

    const teamData = async e => {
        var sport = e.currentTarget.id;
        try {
            setLoading(true);
            const res = await updateTeams(sport);
            setData(res);
            setError('');
        } catch (error) {
            console.log(error.message);
            setError('Error updating team data');
        } finally {
            setLoading(false);
        }
    };

    const leagueData = async e => {
        var sport = e.currentTarget.id;
        try {
            setLoading(true);
            const res = await updateLeagueData(sport);
            setData(res);
            setError('');
        } catch (error) {
            console.log(error.message);
            setError('Error updating league data');
        } finally {
            setLoading(false);
        }
    };

    const propData = async e => {
        var sport = e.currentTarget.id;
        try {
            setLoading(true);
            const res = await updatePropData(sport);
            setData(res);
            setError('');
        } catch (error) {
            console.log(error.message);
            setError('Error updating team data');
        } finally {
            setLoading(false);
        }
    };

    return (
        <ProfileContentContainer>
            <MDBRow>
                <MDBCol md={12} lg={4}>
                    <div>
                        <h3>Active Sports</h3>
                        <MDBBtn onClick={() => allData('games')} color="primary">
                            Games
                        </MDBBtn>
                        <MDBBtn onClick={() => allData('teams')} color="primary">
                            Teams
                        </MDBBtn>
                        <MDBBtn onClick={() => allData('props')} color="primary">
                            Props
                        </MDBBtn>
                        <MDBBtn onClick={() => allData('leagues')} color="primary">
                            Leagues
                        </MDBBtn>
                        <MDBBtn onClick={() => allData('all')} color="primary">
                            All
                        </MDBBtn>
                    </div>
                    {ACTIVE_SPORTS.map(sport => {
                        return (
                            <div style={{ marginTop: '15px' }}>
                                <h3>{String(sport).toUpperCase()}</h3>
                                <MDBBtn onClick={gameData} id={sport} color="primary">
                                    Games
                                </MDBBtn>
                                <MDBBtn onClick={teamData} id={sport} color="primary">
                                    Teams
                                </MDBBtn>
                                <MDBBtn onClick={propData} id={sport} color="primary">
                                    Props
                                </MDBBtn>
                                <MDBBtn onClick={leagueData} id={sport} color="primary">
                                    League
                                </MDBBtn>
                            </div>
                        );
                    })}
                </MDBCol>
                <MDBCol md={12} lg={8}>
                    {loading ? <LoadingSpinner /> : error === '' ? <ReactJson collapsed={true} src={data} /> : <Alert message={error} variant="danger" />}
                </MDBCol>
            </MDBRow>
        </ProfileContentContainer>
    );
};

export default APIAdmin;
