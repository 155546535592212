import styled from 'styled-components';

export const SearchBuilderWrapper = styled.div`
    border-top: 1px solid #ddd;
    margin-bottom: -25px;
    height: 85vh;
    display: flex;
    justify-content: row;
`;

export const SearchDrawer = styled.div`
    padding: 0px 25px 25px 25px !important;
    border-right: 1px solid #ddd !important;
    display: flex;
    flex-direction: column;
    box-shadow: 2px 0px 2px rgba(0, 0, 0, 0.1);
    z-index: 9999;
    width: 500px;
`;

export const SearchFieldContainer = styled.div`
    position: relative;
`;

export const SearchField = styled.div`
    display: flex;
    flex-direction: column;
    margin-bottom: 15px;
`;

export const FieldTitle = styled.span`
    font-size: 16px;
    border-top: 1px solid #ddd;
    border-bottom: 1px solid #ddd;
    margin-left: -25px;
    margin-right: -25px;
    padding: 10px 25px 10px 25px;
    margin-bottom: 10px;
    font-weight: bold;
`;

export const InputTitle = styled.span`
    font-size: 14px;
    font-weight: 700;
    font-family: ${({ theme }) => theme.fonts.heading};
    margin-bottom: 0.25rem;
`;

export const SearchButtonContainer = styled.div`
    display: flex;
    justify-content: flex-end;
    margin-top: auto;
    margin-bottom: 3rem;
`;

export const SearchResultsHeader = styled.div`
    background: #fff;
    display: flex;
    justify-content: center;
    padding: 10px;
    margin-right: -15px;
`;

export const SearchResults = styled.div`
    background: rgb(246, 246, 246);
    height: 100%;
    overflow-x: hidden;
    overflow-y: scroll;
    flex: 1 !important;
`;

export const PaginationContainer = styled.div`
    position: absolute;
    left: 15px;
    top: 5px;
`;
