// Library imports
import moment from 'moment';
// Util imports
import { convertToISOString } from '../../../utils';

export const filterGames = (gameData, value, filters, activeDate) => {
    return gameData.filter(game => {
        // Check if game is on the active date
        if (activeDate !== 'all') {
            if (!moment(game.dateTime).isSame(convertToISOString(activeDate), 'D')) {
                return false;
            }
        }
        // Check if game passes at least 1 filter
        if (filters.length) {
            const passesFilter = filters.some(filter => {
                switch (filter) {
                    case 'road-dogs':
                        return game.awayTeam.odds?.fullGame?.spread > 0 && game.awayTeam.odds?.fullGame?.spread <= 3;
                    case 'road-favorites':
                        return game.awayTeam.odds?.fullGame.moneyline < 0;
                    case 'home-favs':
                        return game.homeTeam.odds?.fullGame.moneyline < -300 || game.homeTeam.odds?.fullGame?.spread < -10;
                    case 'division-game':
                        const divisionGame = game.situations.find(situtaion => situtaion.id === 'division-game');
                        if (divisionGame) {
                            return divisionGame.value;
                        } else {
                            return false;
                        }
                    case 'conference-game':
                        const conferenceGame = game.situations.find(situtaion => situtaion.id === 'conference-game');
                        if (conferenceGame) {
                            return conferenceGame.value;
                        } else {
                            return false;
                        }
                    case 'Upcoming':
                        return game.status === 'not started';
                    case 'Live':
                        return game.status === 'live';
                    default:
                        if (game.advantages.find(adv => adv.id === filter)) {
                            return true;
                        } else {
                            return false;
                        }
                }
            });
            if (!passesFilter) return false;
        }
        // Check if game is included in the query (search) term
        if (!value) return true;
        const normalizedValue = value.toLowerCase();
        const matchesSearch = [
            game.awayTeam.teamName,
            game.homeTeam.teamName,
            game.awayTeam.displayName,
            game.homeTeam.displayName,
            game.awayTeam.abbr,
            game.homeTeam.abbr,
            game.awayTeam.fullName,
            game.homeTeam.fullName,
            game.gameId,
        ]
            .filter(Boolean)
            .some(name => name.toLowerCase().includes(normalizedValue));
        return matchesSearch;
    });
};
