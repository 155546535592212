// Library imports
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import validator from 'validator';
import { MDBBtn, MDBCard, MDBCardBody, MDBCol, MDBIcon, MDBRow } from 'mdb-react-ui-kit';
// Component imports
import Alert from '../../../components/Alert';
import TextInput from '../../../components/InputLibrary/TextInput';
// Util imports
import { scrollToTop } from '../../../utils';
// Styled components
import { CardTitle } from '../../App/GameCards/GameCard/windows/styles';
// Action imports
import { fetchUser } from '../../../store/slices/userSlice';
// API imports
import { deleteUser, updateUser } from '../../../api/user';

const ManageUserData = () => {
    // Redux
    const { user } = useSelector(state => state.user);
    const dispatch = useDispatch();
    // Local state
    const [message, setMessage] = useState('');
    const [saveLoading, setSaveLoading] = useState(false);
    const [confirmDelete, setConfirmDelete] = useState(false);

    const {
        handleSubmit,
        control,
        reset,
        formState: { isValid },
    } = useForm({
        defaultValues: {
            displayName: user?.displayName,
            firstName: user?.reciept?.firstName,
            lastName: user?.reciept?.lastName,
            email: user?.email,
            twitter: user?.reciept?.twitter,
            discord: user?.reciept?.discord,
        },
    });

    useEffect(() => {
        scrollToTop();
        reset({
            displayName: user.displayName,
            firstName: user?.reciept?.firstName,
            lastName: user?.reciept?.lastName,
            email: user.email,
            twitter: user.reciept?.twitter,
            discord: user.reciept?.discord,
        });
    }, [message, user]);

    const onSubmit = async data => {
        try {
            setSaveLoading(true);
            const dataToUpdate = { ...data, oldEmail: user.email, custId: user.cust?.id };
            await updateUser(dataToUpdate);
            dispatch(fetchUser());
            setMessage('Profile update success');
            setTimeout(() => {
                setMessage('');
            }, 3000);
        } catch (error) {
            setMessage(`Error updating profile data.\n${error}`);
        } finally {
            setSaveLoading(false);
        }
    };

    const handleDeleteProfile = async () => {
        try {
            await deleteUser(user.uid, user.email);
            window.location = '/login';
        } catch (error) {
            console.log(`Error deleting profile\n${error.message}`);
            setMessage('Error deleting profile - please try again.');
        }
    };

    return (
        <MDBRow>
            <MDBCol sm={12} md={7}>
                <MDBCard>
                    <CardTitle>Your Information</CardTitle>
                    {message && (
                        <Alert style={{ width: '80%', margin: '0 auto' }} message={message} variant={message.includes('Error') ? 'danger' : 'success'} />
                    )}
                    <MDBCardBody>
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <TextInput name="displayName" label="Display Name" size="lg" control={control} type="text" />
                            <TextInput name="firstName" label="First Name" size="lg" control={control} type="text" />
                            <TextInput name="lastName" label="Last Name" size="lg" control={control} type="text" />
                            <TextInput
                                name="email"
                                label="Email Address"
                                size="lg"
                                control={control}
                                isRequired={true}
                                disabled={true}
                                type="email"
                                rules={{
                                    validate: {
                                        emailFormat: value => validator.isEmail(value) || 'Invalid email format',
                                    },
                                }}
                            />
                            <TextInput name="twitter" label="Twitter" size="lg" control={control} type="text" />
                            <TextInput name="discord" label="Discord" size="lg" control={control} type="text" />
                            <MDBBtn disabled={!isValid} color="success" type="submit">
                                <MDBIcon className="me-2" fas icon="save" />
                                <span>{saveLoading ? 'Saving...' : 'Save'}</span>
                            </MDBBtn>
                        </form>
                    </MDBCardBody>
                </MDBCard>
            </MDBCol>
            <MDBCol sm={12} md={5} className="mt-md-0 mt-3">
                <MDBCard>
                    <CardTitle>Quick Actions</CardTitle>
                    <MDBCardBody>
                        {confirmDelete ? (
                            <>
                                <MDBBtn color="secondary" style={{ marginRight: '5px' }} onClick={() => setConfirmDelete(false)}>
                                    Cancel
                                </MDBBtn>
                                <MDBBtn variant="danger" disabled={saveLoading} onClick={handleDeleteProfile}>
                                    Confirm Delete
                                </MDBBtn>
                            </>
                        ) : (
                            <MDBBtn color="outline-danger" onClick={() => setConfirmDelete(true)}>
                                Delete Profile
                            </MDBBtn>
                        )}
                    </MDBCardBody>
                </MDBCard>
            </MDBCol>
        </MDBRow>
    );
};

export default ManageUserData;
