// Library imports
import React from 'react';
import { ResponsivePie } from '@nivo/pie';
import { arrayOf, number, shape, string } from 'prop-types';
import { useTheme } from 'styled-components';

const PieChart = ({ data = [], colors = ['#d4edda', '#f8d7da'], height = '100%', labelFontSize = 16 }) => {
    const theme = useTheme();

    return (
        <div style={{ height: height }}>
            <ResponsivePie
                data={data.map(pieData => {
                    return {
                        id: pieData.x,
                        label: pieData.x,
                        value: pieData.y,
                    };
                })}
                colors={colors}
                margin={{ top: 5, right: 2, bottom: 5, left: 2 }}
                padAngle={3}
                innerRadius={0.2}
                borderWidth={2}
                enableArcLinkLabels={false}
                arcLabel={e => e.id}
                arcLabelsSkipAngle={10}
                theme={{
                    labels: {
                        text: {
                            fontSize: labelFontSize,
                            fill: theme.colors.black,
                            fontWeight: '700',
                            fontFamily: theme.fonts.heading,
                        },
                    },
                }}
            />
        </div>
    );
};

PieChart.propTypes = {
    data: arrayOf(shape({ x: string, y: number })),
    colors: arrayOf(string),
    height: string,
    labelFontSize: number,
};

export default PieChart;
