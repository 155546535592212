// Library imports
import React, { useEffect, useMemo, useState } from 'react';
import {
    MDBModal,
    MDBModalDialog,
    MDBModalContent,
    MDBModalHeader,
    MDBModalTitle,
    MDBModalBody,
    MDBModalFooter,
    MDBBtn,
    MDBInput,
    MDBListGroupItem,
    MDBTable,
    MDBTableHead,
    MDBTableBody,
    MDBIcon,
} from 'mdb-react-ui-kit';
import moment from 'moment';
import { useSelector, useDispatch } from 'react-redux';
// Component imports
import LoadingSpinner from '../../../components/LoadingSpinner';
import Dropdown from '../../../components/DropDown';
import Alert from '../../../components/Alert';
// Styled components
import { ProfileContentContainer, SearchFilterBar, StyledSearchBar } from '../styles';
import { LabelValueListGroup } from '../../App/styles';
// Util imports
import { EMAIL_TEMPLATES } from '../../../utils/constants';
import { scrollToTop } from '../../../utils';
// Action imports
import { fetchUsers } from '../../../store/slices/appSlice';
// API imports
import { updateReciept } from '../../../api/payment';
import { deleteUser, sendEmail } from '../../../api/user';
// Constant imports
import { FILTERS } from './constants';

const VIPs = () => {
    // Redux
    const { users, loaders } = useSelector(state => state.app);
    const dispatch = useDispatch();
    // Local state
    const [emailTemplate, setEmailTemplate] = useState('renewal');
    const [vipType, setVIPType] = useState(FILTERS['daily-access']);
    const [deleteConfirm, setDeleteConfirm] = useState('');
    const [uid, setUID] = useState('');
    const [error, setError] = useState('');
    const [modalShow, setModalShow] = useState(false);
    const [value, setValue] = useState('');

    const filteredUsers = useMemo(() => {
        return users
            .filter(user => vipType.filter(user))
            .filter(user => {
                if (value) {
                    if (!String(user.email).toLowerCase().includes(value.toLowerCase())) {
                        return false;
                    }
                }
                return true;
            });
    }, [users, value, vipType]);

    useEffect(() => {
        dispatch(fetchUsers());
    }, []);

    useMemo(() => {
        if (deleteConfirm) {
            setModalShow(true);
        }
    }, [deleteConfirm]);

    const handleCloseModal = () => {
        setDeleteConfirm('');
        setModalShow(false);
    };

    const handleSendReminder = async emails => {
        try {
            await sendEmail(emails, emailTemplate);
            setError('');
        } catch (error) {
            console.log(`Error sending email. ${error}`);
            setError(error.message);
            scrollToTop();
        }
    };

    if (loaders.find(loader => loader === 'users-data')) {
        return (
            <ProfileContentContainer>
                <LoadingSpinner />
            </ProfileContentContainer>
        );
    }

    return (
        <ProfileContentContainer>
            {error && <Alert message={error} variant="danger" />}
            <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center', gap: '1rem', margin: '1rem 0' }}>
                <h4>{`Users: ${users.filter(user => vipType.filter(user)).length}`}</h4>
                <h4>{`Expired: ${users.filter(user => FILTERS['expired'].filter(user)).length}`}</h4>
            </div>
            <SearchFilterBar>
                <Dropdown
                    caption="Package Type"
                    dropdownStyle={{
                        border: '1px solid #ddd',
                        minWidth: '250px',
                        flex: 1,
                    }}
                    value={vipType.id}
                    setValue={vipType => setVIPType(FILTERS[vipType])}
                    menuItems={Object.keys(FILTERS)}
                />
                <StyledSearchBar aria-label="Search" id="user-search" value={value} onChange={e => setValue(e.target.value)} />
                <Dropdown
                    caption="Email Type"
                    value={emailTemplate}
                    dropdownStyle={{
                        border: '1px solid #ddd',
                        width: '250px',
                        flex: 1,
                    }}
                    setValue={template => setEmailTemplate(template)}
                    menuItems={EMAIL_TEMPLATES}
                />
                <MDBBtn style={{ maxWidth: '15rem' }} onClick={() => handleSendReminder([...new Set(filteredUsers.map(rec => rec.email))])}>
                    Email All
                </MDBBtn>
            </SearchFilterBar>
            <MDBTable striped responsive>
                <MDBTableHead style={{ position: 'relative' }} dark>
                    <MDBBtn
                        color="link"
                        onClick={() => dispatch(fetchUsers())}
                        style={{ background: 'transparent', position: 'absolute', right: 0, top: 0, padding: 0 }}
                    >
                        <MDBIcon fas icon="sync-alt" size="2x" color="white" style={{ background: 'transparent' }} />
                    </MDBBtn>
                    <tr>
                        {vipType.id.includes('access') || vipType.id.includes('expired')
                            ? ['Details', 'End Date', 'Demote', 'Delete'].map((label, i) => <th key={i}>{label}</th>)
                            : ['Details', 'Delete'].map((label, i) => <th key={i}>{label}</th>)}
                    </tr>
                </MDBTableHead>
                <MDBTableBody>
                    {filteredUsers
                        .sort((a, b) => {
                            var aDate = new Date(a.endDate);
                            var bDate = new Date(b.endDate);
                            return aDate - bDate;
                        })
                        .map(user => {
                            return (
                                <tr style={{ verticalAlign: 'middle' }}>
                                    <td>
                                        <LabelValueListGroup>
                                            <MDBListGroupItem noBorders style={{ padding: 0, backgroundColor: 'transparent' }}>
                                                <label>Email:</label>
                                                <span>{user.email}</span>
                                            </MDBListGroupItem>
                                            <MDBListGroupItem noBorders style={{ padding: 0, backgroundColor: 'transparent' }}>
                                                <label>Twitter:</label>
                                                <span>{user.twitter ? user.twitter : 'NA'}</span>
                                            </MDBListGroupItem>
                                            <MDBListGroupItem noBorders style={{ padding: 0, backgroundColor: 'transparent' }}>
                                                <label>Package:</label>
                                                <span>{user.items[user.items.length - 1].id}</span>
                                            </MDBListGroupItem>
                                        </LabelValueListGroup>
                                    </td>
                                    {(vipType.id.includes('access') || vipType.id.includes('expired')) && <td>{moment(user.endDate).format('MM/DD/YYYY')}</td>}
                                    {(vipType.id.includes('access') || vipType.id.includes('expired')) && (
                                        <td>
                                            <MDBBtn
                                                color="warning"
                                                onClick={async () => {
                                                    await updateReciept(user._id, 'type', 'free-account');
                                                    await updateReciept(user._id, 'endDate', 'never');
                                                    dispatch(fetchUsers());
                                                }}
                                            >
                                                Demote
                                            </MDBBtn>
                                        </td>
                                    )}
                                    <td>
                                        <MDBBtn
                                            color="danger"
                                            onClick={async () => {
                                                if (vipType.id === 'pickpackages') {
                                                    alert('Cannot delete pick packages');
                                                } else {
                                                    setDeleteConfirm(user.email);
                                                    setModalShow(true);
                                                }
                                            }}
                                        >
                                            Delete
                                        </MDBBtn>
                                    </td>
                                </tr>
                            );
                        })}
                </MDBTableBody>
            </MDBTable>
            <MDBModal tabIndex="-1" open={modalShow} onClose={handleCloseModal}>
                <MDBModalDialog centered size="md">
                    <MDBModalContent>
                        <MDBModalHeader>
                            <MDBModalTitle>User Details</MDBModalTitle>
                        </MDBModalHeader>
                        <MDBModalBody>
                            <LabelValueListGroup>
                                <MDBListGroupItem>
                                    <label>Email:</label> <span>{deleteConfirm}</span>
                                </MDBListGroupItem>
                                <MDBListGroupItem>
                                    <label>Uid:</label>
                                    <MDBInput type="text" placeholder="UID to delete..." onChange={e => setUID(e.target.value)} required />
                                </MDBListGroupItem>
                            </LabelValueListGroup>
                        </MDBModalBody>
                        <MDBModalFooter>
                            <MDBBtn outline color="primary" onClick={handleCloseModal}>
                                Cancel
                            </MDBBtn>
                            <MDBBtn
                                color="danger"
                                disabled={loaders.includes('users-data')}
                                onClick={async () => {
                                    try {
                                        await deleteUser(uid, deleteConfirm);
                                        dispatch(fetchUsers());
                                        setError('');
                                    } catch (error) {
                                        setError(`Error deleting profile\n${error}`);
                                        console.error(`Error deleting profile\n${error}`);
                                    } finally {
                                        handleCloseModal();
                                    }
                                }}
                            >
                                Delete User
                            </MDBBtn>
                        </MDBModalFooter>
                    </MDBModalContent>
                </MDBModalDialog>
            </MDBModal>
        </ProfileContentContainer>
    );
};

export default VIPs;
