import { get, patch, post } from './APIHelper';

export const getCustomer = async email => {
    return get(`customer?email=${email}`);
};

export const createPaymentIntent = async ({ product, customer }) => {
    if (customer.reciept) delete customer.reciept;
    if (customer.cust) delete customer.cust;
    return post('stripe/payment', { product, customer });
};

export const createSubscription = async data => {
    return post('stripe/subscription', data);
};

export const updateSubscription = async (product, id, itemId, endDate, cancel = false, renew = false) => {
    return patch('stripe/subscription', { product, id, itemId, endDate, cancel, renew });
};

export const saveReciept = async reciept => {
    return post('reciept', reciept);
};

export const getReciept = async (email, type) => {
    return get(`reciept?email=${email}&type=${type}`);
};

export const getReciepts = async () => {
    return get('reciepts');
};

export const updateReciept = async (id, field, value) => {
    return patch('reciept', { id, field, value });
};
