import { get, httpDelete, patch, post } from './APIHelper';

export const register = async (email, password, displayName = '', packageType) => {
    const { token } = await getAPIToken();
    return post('user', { email, password, displayName, packageType }, { 'x-api-token': token });
};

export const getAPIToken = async () => {
    return get('token');
};

export const updateUser = async data => {
    return patch('user', data);
};

export const deleteUser = async (uid, email) => {
    return httpDelete('user', { uid, email });
};

export const getUser = async () => {
    return get('user');
};

export const sendEmail = async (email, type) => {
    return post('email', { email, type });
};
