export const STATIC_LINKS = [
    {
        id: 'home',
        icon: 'fa-solid fa-home', // More suitable for Home
        label: 'Home',
    },
    {
        id: 'about',
        icon: 'fa-solid fa-users', // More fitting for About
        label: 'About',
    },
    {
        id: 'packages',
        icon: 'fa-solid fa-gift', // Suitable for Packages, as it represents gifts/packages
        label: 'Packages',
    },
    {
        id: 'login',
        icon: 'fa-solid fa-sign-in', // More fitting for login action
        label: 'Log in',
    },
];

export const APP_LINKS = [
    {
        id: 'mlb',
        icon: 'fa-solid fa-baseball-ball',
        label: 'MLB',
    },
    {
        id: 'nfl',
        icon: 'fa-solid fa-football-ball',
        label: 'NFL',
    },
    {
        id: 'cfb',
        icon: 'fa-solid fa-football-ball',
        label: 'CFB',
    },
    {
        id: 'nhl',
        icon: 'fa-solid fa-hockey-puck',
        label: 'NHL',
    },
    {
        id: 'nba',
        icon: 'fa-solid fa-basketball-ball',
        label: 'NBA',
    },
    {
        id: 'cbb',
        icon: 'fa-solid fa-basketball-ball',
        label: 'CBB',
    },
    {
        id: 'profile',
        icon: 'fa-solid fa-user',
        label: '',
    },
];
