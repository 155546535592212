// Library imports
import React, { useState } from 'react';
// Component imports
import SingleTrend from './SingleTrend';
import AllTrends from './AllTrends';
import NoData from '../../../components/NoData';
import TabNav from '../../../components/TabNav';
// Styled components
import { TrendsWrapper } from './styles';
// Hook imports
import useScreenWidth from '../../../utils/hooks/useScreenWidth';
// Util imports
import { ADVANCED_SEARCH_SCREEN_SIZE } from '../../../utils/constants';

const Trends = () => {
    // Local state
    const [subpage, setSubpage] = useState('single');
    // Hooks
    const windowWidth = useScreenWidth();

    if (windowWidth < ADVANCED_SEARCH_SCREEN_SIZE) {
        return (
            <TrendsWrapper>
                <NoData
                    title={'Please try again on a desktop.'}
                    message={'You must be on a desktop device to use this feature.'}
                    variant="danger"
                    containerStyle={{ margin: '25px auto', maxWidth: '90%' }}
                />
            </TrendsWrapper>
        );
    }

    return (
        <div>
            <TabNav activeTab={subpage} setTab={setSubpage} tabs={['single', 'all']} containerStyle={{ width: '201px', margin: '0 auto' }} />
            {subpage === 'single' ? <SingleTrend /> : <AllTrends />}
        </div>
    );
};

export default Trends;
