// Library imports
import React from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
// Util imports
import { EDITABLE_TRENDS } from '../../../../utils/constants';
// Action imports
import { setTrend } from '../../../../store/slices/trendBuilderSlice';
import { LabelValueListGroup } from '../../styles';
import { MDBListGroupItem } from 'mdb-react-ui-kit';

const TrendList = () => {
    const history = useHistory();
    // Redux
    const { trend: activeTrend } = useSelector(state => state.trendBuilder);
    const dispatch = useDispatch();

    return (
        <LabelValueListGroup coloredList={true} hoverable={true}>
            {EDITABLE_TRENDS.map((trend, i) => {
                return (
                    <MDBListGroupItem
                        key={i}
                        onClick={() => {
                            history.push({
                                pathname: location.pathname,
                                search: `?subpage=trends&trend=${trend.id}`,
                            });
                            dispatch(setTrend(trend));
                        }}
                        color={trend.id === activeTrend.id ? 'success' : ''}
                    >
                        <label>{trend.title}</label>
                        <span>{trend.description}</span>
                    </MDBListGroupItem>
                );
            })}
        </LabelValueListGroup>
    );
};

export default TrendList;
