// Libraries imports
import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { MDBCard, MDBCardBody, MDBCol, MDBIcon, MDBListGroup, MDBListGroupItem, MDBRow } from 'mdb-react-ui-kit';
// Component imports
import SportsNav from '../../../../../../components/SportsNav';
import LoadingSpinner from '../../../../../../components/LoadingSpinner';
import PillNav from '../../../../../../components/PillNav';
import TabNav from '../../../../../../components/TabNav';
import Alert from '../../../../../../components/Alert';
import PlayListItem from './PlayListItem';
// Util imports
import { sortPicks } from '../../../../../../utils';
import { FUTURES } from '../../../../../../utils/constants';
// Styled Components
import { LabelValueListGroup } from '../../../../../App/styles';
import { CardHeader, InsightValue, InsightLabel } from './styles';
// API imports
import { vipPickRecord } from '../../../../../../api/picks';

const Weekly = () => {
    // Local state
    const [data, setData] = useState({});
    const [weeklyData, setWeeklyData] = useState([]);
    const [loadingTimeFrame, setLoadingTimeFrame] = useState(true);
    const [vipTimeframe, setVIPTimeFrame] = useState('week');
    const [sport, setSport] = useState('all');
    const [upcomingTab, setUpcomingTab] = useState('Main Card');

    const loadRecordByTimeframe = async () => {
        try {
            const timeFrameRes = await vipPickRecord(vipTimeframe, true);
            const weeklyPicks = sortPicks(timeFrameRes.picks, 'asc').filter(pick => {
                return moment(pick.gameTime).isSameOrAfter(moment().startOf('week'));
            });
            setWeeklyData(weeklyPicks);
            setData(timeFrameRes);
        } catch (error) {
            console.log(`Error getting timeframe picks VIP stats.\n${error.message}`);
        } finally {
            setLoadingTimeFrame(false);
        }
    };

    useEffect(() => {
        loadRecordByTimeframe();
    }, [vipTimeframe]);

    return (
        <MDBRow className="g-2">
            <MDBCol sm={12} md={6}>
                <MDBCard>
                    <CardHeader>Pending Plays</CardHeader>
                    <MDBCardBody>
                        <PillNav tabs={['Main Card', 'Futures']} activeTab={upcomingTab} setActiveTab={setUpcomingTab} />
                        <Alert message="All units represent the risk amount" variant="info" />
                        {loadingTimeFrame ? (
                            <LoadingSpinner />
                        ) : (
                            <MDBListGroup>
                                {upcomingTab === 'Main Card'
                                    ? weeklyData
                                          .filter(pick => pick.result === 'pending')
                                          .map(pick => {
                                              return <PlayListItem key={pick.pickId} pick={pick} />;
                                          })
                                    : FUTURES.map(future => {
                                          return <PlayListItem key={future.pickId} pick={future} />;
                                      })}
                            </MDBListGroup>
                        )}
                    </MDBCardBody>
                </MDBCard>
            </MDBCol>
            <MDBCol sm={12} md={6}>
                <MDBCard>
                    <CardHeader>Quick Insights</CardHeader>
                    <MDBCardBody>
                        <TabNav tabs={['week', 'month']} activeTab={vipTimeframe} setTab={setVIPTimeFrame} containerStyle={{ marginBottom: '1rem' }} />
                        <SportsNav click={setSport} activeItem={sport} />
                        {loadingTimeFrame ? (
                            <LoadingSpinner />
                        ) : (
                            <LabelValueListGroup>
                                <MDBListGroupItem>
                                    <InsightLabel>Record:</InsightLabel>
                                    <InsightValue>{`${data.record[sport].wins}-${data.record[sport].losses}`}</InsightValue>
                                </MDBListGroupItem>
                                <MDBListGroupItem>
                                    <InsightLabel>Units:</InsightLabel>
                                    <InsightValue>
                                        {`${Number(data.record[sport].units).toFixed(2)}u`}{' '}
                                        {data.record[sport].units >= 0 ? (
                                            <MDBIcon color="success" icon="arrow-up" />
                                        ) : (
                                            <MDBIcon color="danger" icon="arrow-down" />
                                        )}
                                    </InsightValue>
                                </MDBListGroupItem>
                                <MDBListGroupItem>
                                    <InsightLabel>ROI:</InsightLabel>
                                    <InsightValue>{`${Number(data.record[sport].roi).toFixed(2)}%`}</InsightValue>
                                </MDBListGroupItem>
                            </LabelValueListGroup>
                        )}
                    </MDBCardBody>
                </MDBCard>
                <MDBCard className="mt-2">
                    <CardHeader>Weekly Results</CardHeader>
                    <MDBCardBody>
                        <Alert message="All units represent the risk amount" variant="info" />
                        <MDBListGroup>
                            {weeklyData
                                .filter(pick => pick.result !== 'pending')
                                .map(pick => {
                                    const color = pick.result === 'win' ? 'success' : pick.result === 'loss' ? 'danger' : 'warning';
                                    return <PlayListItem key={pick.pickId} pick={pick} color={color} />;
                                })}
                        </MDBListGroup>
                    </MDBCardBody>
                </MDBCard>
            </MDBCol>
        </MDBRow>
    );
};

export default Weekly;
