// Library imports
import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { MDBCard, MDBCardBody } from 'mdb-react-ui-kit';
// Component imports
import BarChart from '../../../components/Graphs/BarChart';
import PieChart from '../../../components/Graphs/PieChart';
import TrendSearchFilter from '../../../components/SearchFilters/TrendSearchFilter';
import LoadingSpinner from '../../../components/LoadingSpinner';
import DoubleBarChart from '../../../components/Graphs/DoubleBarChart';
import Grid from '../../../components/Layouts/Grid';
import Dropdown from '../../../components/DropDown';
// API imports
import { getTrends } from '../../../api/trends';
// Styled components
import { ButtonContainer, TrendDescription, TrendTitle } from './styles';

const dayMap = {
    Sun: 0,
    Mon: 1,
    Tue: 2,
    Wed: 3,
    Thu: 4,
    Fri: 5,
    Sat: 6,
};

const AllTrends = () => {
    // Redux
    const { sport, teams } = useSelector(state => state.app);
    // Local state
    const [data, setData] = useState([]);
    const [winnerTypes, setWinnerTypes] = useState({});
    const [loading, setLoading] = useState(false);
    const [value, setValue] = useState('');
    const [activeTeam, setActiveTeam] = useState(0);

    const getTeamData = (value, property) => {
        return teams.find(team => team[property] === value || team.city === value);
    };

    useEffect(() => {
        async function fetchData() {
            try {
                setLoading(true);
                const trendsRes = await getTrends(sport);
                setData(trendsRes);
                let types = {};
                trendsRes.forEach(trend => (types[trend.id] = trend.winnerTypes[0]));
                setWinnerTypes(types);
            } catch (error) {
                console.log(`Error getting trend data\n${error}`);
            } finally {
                setLoading(false);
            }
        }
        fetchData();
    }, [sport]);

    useEffect(() => {
        const teamsById = teams.map(team => team.teamId);
        setActiveTeam(teamsById[0]);
    }, [teams]);

    const renderTrendGraph = trend => {
        if (trend.type === 'pie') {
            return (
                <div style={{ height: '350px' }}>
                    <PieChart
                        showTitle={false}
                        title={trend.title}
                        data={[
                            {
                                x: `Wins: ${Math.trunc((trend.data.wins / trend.data.entries) * 100)}%`,
                                y: (trend.data.wins / trend.data.entries) * 100,
                            },
                            {
                                x: `Losses: ${Math.trunc((trend.data.losses / trend.data.entries) * 100)}%`,
                                y: (trend.data.losses / trend.data.entries) * 100,
                            },
                        ]}
                    />
                </div>
            );
        }
        if (trend.id === 'key-factors') {
            return (
                <BarChart
                    xAxisLabel="factor"
                    yAxisLabel="Win %"
                    yAxisKey="Win %"
                    barWidth={sport === 'mlb' ? 10 : 20}
                    data={trend.data
                        .filter(factor => factor.type !== 'pitcher')
                        .map(factor => {
                            if (factor[winnerTypes[trend.id]].entries !== 0) {
                                return {
                                    factor: factor.label,
                                    ['Win %']: (factor[winnerTypes[trend.id]].wins / factor[winnerTypes[trend.id]].entries) * 100,
                                };
                            }
                            return { factor: factor.label, ['Win %']: 0 };
                        })}
                    symbol="%"
                />
            );
        }
        if (trend.id === 'key-factors-starting-pitcher') {
            return (
                <BarChart
                    xAxisLabel="factor"
                    yAxisLabel="Win %"
                    yAxisKey="Win %"
                    barWidth={sport === 'mlb' ? 10 : 20}
                    data={trend.data
                        .filter(factor => factor.type === 'pitcher')
                        .map(factor => {
                            if (factor['moneyline'].entries !== 0) {
                                return {
                                    factor: factor.label,
                                    ['Win %']: (factor['moneyline'].wins / factor['moneyline'].entries) * 100,
                                };
                            }
                            return { factor: factor.label, ['Win %']: 0 };
                        })}
                    symbol="%"
                />
            );
        }
        if (trend.id === 'days-of-week') {
            return (
                <DoubleBarChart
                    xAxisLabel="day"
                    yAxisLabel="Win %"
                    keys={[
                        { label: 'Favorite Win %', dataValue: 'favWinPercent' },
                        { label: 'Dog Win %', dataValue: 'dogWinPercent' },
                    ]}
                    barWidth={sport === 'mlb' ? 10 : 20}
                    data={Object.keys(trend.data[winnerTypes[trend.id]])
                        .sort((a, b) => {
                            return dayMap[a] - dayMap[b];
                        })
                        .map(day => {
                            return { x: day, ...trend.data[winnerTypes[trend.id]][day] };
                        })}
                    symbol="%"
                />
            );
        }
        if (trend.id === 'team-days-of-week') {
            const teamData = trend.data[activeTeam];
            if (!teamData?.[winnerTypes[trend.id]]) return <div>No data available for this team</div>;
            return (
                <DoubleBarChart
                    xAxisLabel="day"
                    yAxisLabel="Win %"
                    keys={[
                        { label: 'Wins', dataValue: 'wins' },
                        { label: 'Losses', dataValue: 'losses' },
                    ]}
                    barWidth={sport === 'mlb' ? 10 : 20}
                    data={Object.keys(teamData[winnerTypes[trend.id]])
                        .sort((a, b) => {
                            return dayMap[a] - dayMap[b];
                        })
                        .map(day => {
                            return {
                                x: day,
                                wins: teamData[winnerTypes[trend.id]][day].wins / teamData[winnerTypes[trend.id]][day].entries,
                                losses: teamData[winnerTypes[trend.id]][day].losses / teamData[winnerTypes[trend.id]][day].entries,
                            };
                        })}
                    symbol="%"
                />
            );
        }
        if (trend.id === 'ratings') {
            return (
                <BarChart
                    xAxisLabel="Rating"
                    yAxisLabel="Win %"
                    data={Object.keys(trend.data[winnerTypes[trend.id]]).map(index => {
                        const ratingData = trend.data[winnerTypes[trend.id]][index];
                        return {
                            x: `${ratingData.range[0]}-${ratingData.range[1]}`,
                            y: ratingData.entries === 0 ? 0 : (ratingData.wins / ratingData.entries) * 100,
                        };
                    })}
                    symbol="%"
                />
            );
        }
        if (trend.id === 'power-diff') {
            return (
                <BarChart
                    xAxisLabel="Difference"
                    yAxisLabel="Win %"
                    data={Object.keys(trend.data[winnerTypes[trend.id]]).map((data, i) => {
                        return {
                            Difference: `${trend.data[winnerTypes[trend.id]][data].range[0]} - ${
                                i === trend.data[winnerTypes[trend.id]].length - 1 ? '+' : trend.data[winnerTypes[trend.id]][data].range[1].toFixed(0)
                            }`,
                            ['Win %']:
                                trend.data[winnerTypes[trend.id]][data].entries === 0
                                    ? 0
                                    : (trend.data[winnerTypes[trend.id]][data].wins / trend.data[winnerTypes[trend.id]][data].entries) * 100,
                        };
                    })}
                    symbol="%"
                />
            );
        }
    };

    return (
        <div>
            <TrendSearchFilter value={value} setValue={setValue} />
            <Grid>
                {loading ? (
                    <LoadingSpinner />
                ) : (
                    data
                        .filter(trend => {
                            if (
                                Object.keys(trend.data).length === 0 ||
                                (trend.data.entries && trend.data.entries === 0) ||
                                (Array.isArray(trend.data) && trend.data.length === 0)
                            ) {
                                console.log(`No data for trend: ${trend.title}`);
                                return false;
                            }
                            const lowercaseValue = String(value).toLowerCase();
                            if (!value) {
                                return true;
                            }
                            if (String(trend.title).toLowerCase().includes(lowercaseValue)) {
                                return true;
                            }
                            return false;
                        })
                        .map((trend, i) => {
                            return (
                                <MDBCard style={{ width: trend.id.includes('key-factor') ? '800px' : '500px' }} key={i}>
                                    <MDBCardBody>
                                        <TrendTitle>{trend.title}</TrendTitle>
                                        <TrendDescription>{trend.description}</TrendDescription>
                                        <ButtonContainer>
                                            <Dropdown
                                                menuItems={trend.winnerTypes.map(option => option)}
                                                value={winnerTypes[trend.id]}
                                                setValue={value => setWinnerTypes({ ...winnerTypes, [trend.id]: value })}
                                            />
                                            {trend.id === 'team-days-of-week' && (
                                                <Dropdown
                                                    menuItems={teams.map(team => team.teamId)}
                                                    value={activeTeam}
                                                    setValue={value => setActiveTeam(value)}
                                                />
                                            )}
                                        </ButtonContainer>
                                        {renderTrendGraph(trend)}
                                    </MDBCardBody>
                                </MDBCard>
                            );
                        })
                )}
            </Grid>
        </div>
    );
};

export default AllTrends;
