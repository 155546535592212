// Library imports
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
// API imports
import { getUpcomingGames } from '../../api/games';
import { getAllTeamData } from '../../api/teams';
import { getLeagueData } from '../../api/leagues';
import { getPropData } from '../../api/props';
import { getReciepts } from '../../api/payment';
// Firebase imports
import firebase from 'firebase';

const initialState = {
    sport: 'mlb',
    subPage: 'games',
    previousSubPage: '',
    editGame: {
        id: 0,
        db: 'firebase',
    },
    filters: ['Upcoming'],
    openDrawer: '',
    loaders: ['game-data', 'users-data', 'team-data', 'league-data', 'prop-data'],
    propData: null,
    propCategory: 'playerProps',
    propName: '',
    leagueData: null,
    gameData: [],
    teams: [],
    users: [],
    error: '',
};

export const fetchInitialData = createAsyncThunk('app/fetchInitialData', async (_, { rejectWithValue }) => {
    try {
        const settings = await firebase.firestore().collection('app').doc('settings').get();
        return settings.data();
    } catch {
        console.log(`Error fetching initial data. ${error.message}`);
        return rejectWithValue(`Error fetching initial data. ${error.message}`);
    }
});

export const fetchProps = createAsyncThunk('app/fetchProps', async (_, { getState, rejectWithValue }) => {
    try {
        const { app } = getState();
        const data = await getPropData(app.sport);
        return data;
    } catch (error) {
        console.log(`Error getting prop data. ${error.message}`);
        return rejectWithValue(`Error getting prop data. ${error.message}`);
    }
});

export const fetchLeague = createAsyncThunk('app/fetchLeague', async (_, { getState, rejectWithValue }) => {
    try {
        const { app } = getState();
        const data = await getLeagueData(app.sport);
        return data;
    } catch (error) {
        console.log(`Error getting league data. ${error.message}`);
        return rejectWithValue(`Error getting league data. ${error.message}`);
    }
});

export const fetchGames = createAsyncThunk('app/fetchGames', async (_, { getState, rejectWithValue }) => {
    try {
        const { app, user: userState } = getState();
        const accountType = userState.user.reciept ? userState.user.reciept.type : 'access';
        const gameInfo = await getUpcomingGames(app.sport, accountType);
        return gameInfo;
    } catch (error) {
        console.log(`Error getting game data. ${error.message}`);
        return rejectWithValue(`Error getting game data. ${error.message}`);
    }
});

export const fetchTeams = createAsyncThunk('app/fetchTeams', async (_, { getState, rejectWithValue }) => {
    try {
        const { app } = getState();
        const teamsRes = await getAllTeamData(app.sport);
        return teamsRes;
    } catch (error) {
        console.log(`Error getting team data. ${error.message}`);
        return rejectWithValue(error.message);
    }
});

export const fetchUsers = createAsyncThunk('app/fetchUsers', async (_, { rejectWithValue }) => {
    try {
        const data = await getReciepts();
        return data;
    } catch (error) {
        console.log(`Error getting users data. ${error.message}`);
        return rejectWithValue(error.message);
    }
});

const appSlice = createSlice({
    name: 'app',
    initialState: initialState,
    reducers: {
        setSport(state, { payload }) {
            state.sport = payload;
            state.previousSubPage = state.subPage;
            state.subPage = 'games';
        },
        setEditGame(state, { payload }) {
            state.editGame.id = payload.id;
            state.editGame.db = payload.db;
        },
        setSubPage(state, { payload }) {
            state.previousSubPage = state.subPage;
            state.subPage = payload;
        },
        setFilters(state, { payload }) {
            if (payload === 'clear') {
                state.filters = [];
            } else {
                state.filters = [payload];
            }
        },
        setDrawer(state, { payload }) {
            state.openDrawer = payload;
        },
        addLoader(state, { payload }) {
            state.loaders = [...state.loaders, payload];
        },
        removeLoader(state, { payload }) {
            state.loaders = state.loaders.filter(loader => loader !== payload);
        },
        setPropName(state, { payload }) {
            state.propName = payload;
        },
        setPropCategory(state, { payload }) {
            state.propCategory = payload;
        },
    },
    extraReducers: builder => {
        builder.addCase(fetchInitialData.pending, state => {
            state.loaders = [...state.loaders, 'initial-data'];
        });
        builder.addCase(fetchInitialData.fulfilled, (state, { payload }) => {
            state.loaders = state.loaders.filter(loader => loader !== 'initial-data');
        });
        builder.addCase(fetchInitialData.rejected, (state, { payload }) => {
            state.error = `Error fetching initial data.\n${payload}`;
            state.loaders = state.loaders.filter(loader => loader !== 'initial-data');
        });
        builder.addCase(fetchProps.pending, state => {
            state.loaders = [...state.loaders, 'prop-data'];
        });
        builder.addCase(fetchProps.fulfilled, (state, { payload }) => {
            state.propData = payload;
            state.propName = Object.keys(payload[state.propCategory])[0];
            state.loaders = state.loaders.filter(loader => loader !== 'prop-data');
        });
        builder.addCase(fetchProps.rejected, (state, { payload }) => {
            state.error = `Error loading prop data.\n${payload}`;
            state.loaders = state.loaders.filter(loader => loader !== 'prop-data');
        });
        builder.addCase(fetchLeague.pending, state => {
            state.loaders = [...state.loaders, 'league-data'];
        });
        builder.addCase(fetchLeague.fulfilled, (state, { payload }) => {
            state.leagueData = payload;
            state.loaders = state.loaders.filter(loader => loader !== 'league-data');
        });
        builder.addCase(fetchLeague.rejected, (state, { payload }) => {
            state.error = `Error loading league data.\n${payload}`;
            state.loaders = state.loaders.filter(loader => loader !== 'league-data');
        });
        builder.addCase(fetchGames.pending, state => {
            state.loaders = [...state.loaders, 'game-data'];
        });
        builder.addCase(fetchGames.fulfilled, (state, { payload }) => {
            state.gameData = payload;
            state.loaders = state.loaders.filter(loader => loader !== 'game-data');
        });
        builder.addCase(fetchGames.rejected, (state, { payload }) => {
            state.error = `Error loading games.\n${payload}`;
            state.loaders = state.loaders.filter(loader => loader !== 'game-data');
        });
        builder.addCase(fetchTeams.pending, state => {
            state.loaders = [...state.loaders, 'team-data'];
        });
        builder.addCase(fetchTeams.fulfilled, (state, { payload }) => {
            state.teams = payload.sort((a, b) => (a.teamName < b.teamName ? -1 : 1));
            state.loaders = state.loaders.filter(loader => loader !== 'team-data');
        });
        builder.addCase(fetchTeams.rejected, (state, { payload }) => {
            state.error = `Error getting team information. ${payload}`;
            state.loaders = state.loaders.filter(loader => loader !== 'team-data');
        });
        builder.addCase(fetchUsers.pending, state => {
            state.loaders = [...state.loaders, 'users-data'];
        });
        builder.addCase(fetchUsers.fulfilled, (state, { payload }) => {
            state.users = payload;
            state.loaders = state.loaders.filter(loader => loader !== 'users-data');
        });
        builder.addCase(fetchUsers.rejected, (state, { payload }) => {
            state.error = `Error fetching users.\n${payload}`;
            state.loaders = state.loaders.filter(loader => loader !== 'users-data');
        });
    },
});

export const { setSport, setFilters, setDrawer, addLoader, removeLoader, setPropName, setPropCategory, setSubPage, setEditGame } = appSlice.actions;

export default appSlice.reducer;
