// Library imports
import React, { useState, useEffect } from 'react';
import queryString from 'query-string';
import { Helmet } from 'react-helmet';
import { MDBBtn, MDBCardBody } from 'mdb-react-ui-kit';
import { useForm } from 'react-hook-form';
// Component imports
import LayoutWithHeader from '../../components/Layouts/LayoutWithHeader';
import TextInput from '../../components/InputLibrary/TextInput';
import Alert from '../../components/Alert';
// Util imports
import { scrollToTop } from '../../utils';
// Firebase imports
import firebase from 'firebase';
import { login } from '../../services/Firebase';
// Styled components
import { ContentContainer, LoginFormContainer, HelperButton, RightsText, FormTitle } from './styles';

const Login = ({ location }) => {
    const [form, setForm] = useState('login');
    const [message, setMessage] = useState('');
    // Hooks
    const { control, handleSubmit } = useForm({
        mode: 'onBlur',
    });

    useEffect(() => {
        scrollToTop();
        setMessage('');
    }, [form]);

    const onSubmit = async data => {
        const { email, password } = data;
        try {
            const values = queryString.parse(location.search);
            if (form === 'login') {
                await login(email, password, values.direct);
            }
            if (form === 'forgot-password') {
                await firebase.auth().sendPasswordResetEmail(email);
                setMessage('Password reset email set successfully.');
            }
        } catch (error) {
            var errorMessage = error.message ? error.message : error;
            if (error.code === 'auth/user-not-found') {
                errorMessage = 'User not found. Please check your email and try again.';
            }
            if (error.code === 'auth/wrong-password') {
                errorMessage = 'Incorrect password. Please try again.';
            }
            if (error.code === 'auth/invalid-email') {
                errorMessage = 'Invalid email address. Please check your email and try again.';
            }
            if (error.code === 'auth/too-many-requests') {
                errorMessage = 'Too many requests. Please try again later.';
            }
            if (error.code === 'auth/user-disabled') {
                errorMessage = 'User account has been disabled. Please contact support.';
            }
            if (error.code === 'auth/invalid-continue-uri') {
                errorMessage = 'Invalid continue URI. Please try again.';
            }
            if (error.code === 'auth/invalid-credential') {
                errorMessage = 'Invalid credential. Please try again.';
            }
            if (error.code === 'auth/user-not-found') {
                errorMessage = 'User not found. Please check your email and try again.';
            }
            setMessage(errorMessage);
        }
    };

    return (
        <LayoutWithHeader page="login">
            <Helmet>
                <title>Login | Line Prophet</title>
                <meta
                    name="description"
                    content="Line Prophet gives you everything you need to gain an edge over the books. Our supercomputer gives an algorithm-generated prediction for every game. We follow the most relevant trends using the most up to date data."
                />
                <meta name="viewport" content="width=device-width, initial-scale=1.0" />

                {/* Open Graph / Facebook */}
                <meta property="og:type" content="website" />
                <meta property="og:title" content="Login | Line Prophet" />
                <meta
                    property="og:description"
                    content="Line Prophet gives you everything you need to gain an edge over the books. Our supercomputer gives an algorithm-generated prediction for every game. We follow the most relevant trends using the most up to date data."
                />
                <meta property="og:url" content="https://www.lineprophet.com/" />
                <meta property="og:image" content="https://lineprophet.com/static/media/logo-icon.3f56cee251416e22eb67.png" />

                {/* Twitter */}
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:title" content="Login | Line Prophet" />
                <meta
                    name="twitter:description"
                    content="Line Prophet gives you everything you need to gain an edge over the books. Our supercomputer gives an algorithm-generated prediction for every game. We follow the most relevant trends using the most up to date data."
                />
                <meta name="twitter:image" content="https://lineprophet.com/static/media/logo-icon.3f56cee251416e22eb67.png" />
                <meta name="twitter:site" content="@LineProphetLLC" />
                <meta name="twitter:creator" content="@LineProphetLLC" />

                {/* Favicon */}
                <link rel="icon" href="/favicon.ico" />
            </Helmet>
            <ContentContainer fluid>
                <LoginFormContainer>
                    <FormTitle>{form === 'login' ? 'Log In' : 'Password Reset'}</FormTitle>
                    <MDBCardBody>
                        {message && <Alert style={{ marginTop: 0 }} message={message} variant={message.includes('success') ? 'success' : 'danger'} />}
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <TextInput size="lg" control={control} isRequired={true} name="email" id="email" label="Email" type="email" />
                            {form !== 'forgot-password' && (
                                <TextInput size="lg" control={control} isRequired={true} name="password" id="password" label="Password" type="password" />
                            )}
                            <MDBBtn variant="primary" type="submit" style={{ width: '100%' }}>
                                {form === 'forgot-password' ? 'Recover Password' : 'Enter'}
                            </MDBBtn>
                        </form>
                        {form === 'login' ? (
                            <HelperButton color="link" onClick={() => setForm('forgot-password')}>
                                Forgot Password?
                            </HelperButton>
                        ) : (
                            <HelperButton color="link" onClick={() => setForm('login')}>
                                Return to Login
                            </HelperButton>
                        )}
                        <RightsText>©2025 All Rights Reserved. Line Prophet® is a registered trademark of Line Prophet, LLC.</RightsText>
                    </MDBCardBody>
                </LoginFormContainer>
            </ContentContainer>
        </LayoutWithHeader>
    );
};

export default Login;
