// Library imports
import React, { useState } from 'react';
import { MDBPopover, MDBPopoverBody, MDBIcon } from 'mdb-react-ui-kit';
import { useTheme } from 'styled-components';
// Styled components
import { KeyContainer, KeyItemContainer, StyledIcon } from './styles';
import { HighLightedField } from '../../styles';

const ToolTip = () => {
    // Hooks
    const theme = useTheme();
    // Local state
    const [show, setShow] = useState(false);

    return (
        <MDBPopover
            isOpen={show}
            btnChildren={
                <StyledIcon>
                    <MDBIcon icon="circle-info" />
                </StyledIcon>
            }
            tag="a"
            btnClassName="popover-btn"
            color="none"
            size="sm"
            placement="right-end"
            toggle={() => setShow(!show)}
        >
            <MDBPopoverBody
                style={{
                    width: '400px',
                    backgroundColor: theme.colors.white,
                    boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
                    borderRadius: '8px',
                    zIndex: '9999',
                    border: `1px solid ${theme.colors.black}`,
                }}
            >
                <p style={{ marginTop: 0 }}>
                    <span style={{ fontWeight: 'bold', marginRight: '5px' }}>Summary:</span>
                    <span>
                        Implied probability translates a moneyline into a win percentage, reflecting the bookmaker's estimate of an event's likelihood. By
                        converting these odds, you can determine the implied probability of winning.
                    </span>
                </p>
                <p>
                    <span style={{ fontWeight: 'bold', marginRight: '5px' }}>Example:</span>
                    <span>A moneyline of +150 suggests a win chance of around 40%, while -150 translates to a chance of about 60%.</span>
                </p>
                <KeyContainer>
                    <span style={{ fontWeight: 'bold', marginRight: '5px' }}>Legend</span>
                    <KeyItemContainer>
                        <HighLightedField variant="primary">
                            <span>-150</span>
                            <span style={{ display: 'block', fontSize: '12px' }}>55.43%</span>
                        </HighLightedField>
                        <span>The blue container represents the favored implied probability according to Vegas odds.</span>
                    </KeyItemContainer>
                </KeyContainer>
                <KeyContainer>
                    <span style={{ fontWeight: 'bold', marginRight: '5px' }}>Moneylines</span>
                    <KeyItemContainer>
                        <HighLightedField variant="success">
                            <span>-190</span>
                            <span style={{ display: 'block', fontSize: '12px' }}>65.43%</span>
                        </HighLightedField>
                        <span>
                            The green container represents a higher implied probability from the Line Prophet algorithm in relation to the Vegas number.
                        </span>
                    </KeyItemContainer>
                    <KeyItemContainer>
                        <HighLightedField variant="danger">
                            <span>+130</span>
                            <span style={{ display: 'block', fontSize: '12px' }}>45.43%</span>
                        </HighLightedField>
                        <span>The red container represents a lower implied probability from the Line Prophet algorithm in relation to the Vegas number.</span>
                    </KeyItemContainer>
                </KeyContainer>
                <KeyContainer>
                    <span style={{ fontWeight: 'bold', marginRight: '5px' }}>Spreads</span>
                    <KeyItemContainer>
                        <HighLightedField variant="success">
                            <span>-1.02</span>
                            <span style={{ display: 'block', fontSize: '12px' }}>-.03</span>
                        </HighLightedField>
                        <span>The green container represents that the predicted score from the algorithm has this team covering the Vegas line.</span>
                    </KeyItemContainer>
                    <KeyItemContainer>
                        <HighLightedField variant="danger">
                            <span>+1.02</span>
                            <span style={{ display: 'block', fontSize: '12px' }}>+.03</span>
                        </HighLightedField>
                        <span>The red container represents that the predicted score from the algorithm does not have this team covering the Vegas line.</span>
                    </KeyItemContainer>
                </KeyContainer>
            </MDBPopoverBody>
        </MDBPopover>
    );
};

export default ToolTip;
