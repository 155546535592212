// Library imports
import firebase from 'firebase';
// Util imports
import { ACTIVE_SPORTS } from '../utils/constants';

const firebaseConfig = {
    apiKey: 'AIzaSyBgOTkrcZSHaryM5t8wrOmWsC9xevLvwx0',
    authDomain: 'line-prophet.firebaseapp.com',
    databaseURL: 'https://line-prophet.firebaseio.com',
    projectId: 'line-prophet',
    storageBucket: 'line-prophet.appspot.com',
    messagingSenderId: '1068006183477',
    appId: '1:1068006183477:web:521ce631310b2a740cd77c',
};

if (!firebase.apps.length) {
    firebase.initializeApp(firebaseConfig);
}

export const authListener = () => {
    return new Promise((resolve, reject) => {
        firebase.auth().onAuthStateChanged(async user => {
            if (user) {
                console.log('--- User authenticated! ---');
                resolve(user);
            } else {
                window.location = '/login';
                reject('--- Error authenticating user! ---');
            }
        });
    });
};

export const getUserToken = async () => {
    if (firebase.auth().currentUser === null) return null;
    return await firebase.auth().currentUser.getIdToken();
};

export const login = async (email, password) => {
    const user = await firebase.auth().signInWithEmailAndPassword(email, password);
    if (user) {
        window.location = `/sports/${ACTIVE_SPORTS[0]}`;
    }
};

// NOTE this is here because its the only call we don't send to APIHelper because that will throw an error for non admins
export const isUserAdmin = async () => {
    const token = await getUserToken();
    const res = await fetch(`${process.env.REACT_APP_HOST}/api/user/isAdmin`, {
        method: 'GET',
        headers: {
            authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
        },
    });
    // NOTE we don't want to throw an error here because it will ruin the entire fetchUser operation for non admins
    const json = await res.json();
    return json;
};
