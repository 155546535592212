import { get, httpDelete, patch, post } from './APIHelper';

export const addPick = async pickDetails => {
    return post('pick', pickDetails);
};

export const updatePick = async pickDetails => {
    return patch('pick', pickDetails);
};

export const deletePick = async pickId => {
    return httpDelete('pick', { pickId });
};

export const vipPickRecord = async timeframe => {
    return get(`pick/vip/record?timeframe=${timeframe}`);
};

export const vipPickRecordYTD = async () => {
    return get('pick/vip/record/ytd');
};
