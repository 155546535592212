// Library imports
import styled from 'styled-components';
import { MDBCardHeader } from 'mdb-react-ui-kit';

export const CardHeader = styled(MDBCardHeader)`
    font-size: 1.25rem;
    font-weight: 700;
    font-family: ${({ theme }) => theme.fonts.heading};
    color: ${({ theme }) => theme.colors.black};
`;

export const InsightValue = styled.span`
    display: block;
    font-size: 2rem !important;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 0.25rem;
    font-weight: 700 !important;
    font-family: ${({ theme }) => theme.fonts.heading};
`;

export const InsightLabel = styled.label``;
