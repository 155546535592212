// Library imports
import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { MDBCheckbox, MDBListGroupItem } from 'mdb-react-ui-kit';
// Component imports
import { queryFields } from './queryFields';
// Action imports
import { toggleField } from '../../../store/slices/queryBuilderSlice';
// Styled components
import { LabelValueListGroup } from '../styles';

const AddFilters = () => {
    // Redux
    const { activeFields } = useSelector(state => state.queryBuilder);
    const dispatch = useDispatch();

    return (
        <div>
            <LabelValueListGroup direction="row" variant="flush">
                {Object.keys(queryFields).map(id => {
                    const fieldData = queryFields[id];
                    return (
                        <MDBListGroupItem
                            key={id}
                            style={{
                                margin: '0px -25px',
                                padding: '15px 25px',
                            }}
                        >
                            <label>{fieldData.title}</label>
                            <MDBCheckbox
                                name="checkNoLabel"
                                id="checkboxNoLabel"
                                onChange={() => dispatch(toggleField(fieldData.id))}
                                checked={activeFields.includes(fieldData.id)}
                            />
                        </MDBListGroupItem>
                    );
                })}
            </LabelValueListGroup>
        </div>
    );
};

export default AddFilters;
