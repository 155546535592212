// Library imports
import styled from 'styled-components';
import { MDBNavbar, MDBNavbarLink, MDBNavbarNav } from 'mdb-react-ui-kit';
// Util imports
import { XL_SCREEN_WIDTH, MEDIUM_SCREEN_WIDTH, LARGE_SCREEN_WIDTH } from '../../utils/constants';

export const StyledNavbar = styled(MDBNavbar)`
    padding: 10px 20px; // Vertical and Horizontal Padding
    background-color: ${({ transparent, theme }) => (transparent === 'true' ? 'transparent' : theme.colors.white)};
    box-shadow: ${({ transparent, shadow }) => (transparent === 'true' || !shadow ? 'none' : '0 4px 12px 0 #00000012, 0 2px 4px #0000000d')};
    border-bottom: ${({ transparent, theme }) => (transparent === 'true' ? 'none' : `1px solid ${theme.colors.borderColor}`)};
`;

export const StyledNavbarNav = styled(MDBNavbarNav)``;

export const StyledNavbarLink = styled(MDBNavbarLink)`
    font-weight: bold;
    padding: 0.75rem !important;
    display: flex;
    align-items: center;
    font-size: 1rem;
    line-height: 1rem;
    border-radius: 5px;
    border: 1px solid transparent;
    margin-right: 0.25rem !important;
    transition: all 0.2s ease-in-out;
    background: ${({ active, dark, theme, transparent }) =>
        active === 'true' ? (dark === 'true' && transparent === 'true' ? theme.colors.white : theme.colors.primaryDark) : 'transparent'};
    color: ${({ active, dark, theme, transparent }) => {
        if (active === 'true') {
            return dark === 'true' && transparent === 'true' ? theme.colors.primaryDark : theme.colors.white;
        }
        return dark === 'true' && transparent === 'true' ? theme.colors.white : theme.colors.primaryDark;
    }} !important;
    &:hover {
        background: ${({ theme }) => theme.colors.primaryDark} !important;
        color: ${({ theme }) => theme.colors.white} !important;
    }
    i {
        margin-right: 0.2rem;
        font-size: 1.15rem;
    }

    @media (max-width: ${LARGE_SCREEN_WIDTH}) {
        width: 100%;
        border-radius: 6px;
        margin-top: 0.5rem;
    }
`;

export const Logo = styled.img`
    height: 39px;
    width: 220px;
`;

export const SocialIconContainer = styled.div`
    display: inline-block;
    @media (max-width: ${XL_SCREEN_WIDTH}) {
        width: 100%;
    }
`;

export const BannerWrapper = styled.div`
    background-color: ${({ theme }) => theme.colors.green};
    background-image: linear-gradient(315deg, #3bb78f 0%, #0bab64 74%);
    padding: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    @media (max-width: ${MEDIUM_SCREEN_WIDTH}) {
        flex-direction: column;
    }
`;

export const BannerText = styled.div`
    display: flex;
    flex-direction: column;
    margin-right: 15px;
    align-items: center;

    @media (max-width: ${MEDIUM_SCREEN_WIDTH}) {
        margin-right: 0;
    }
`;
