// Library imports
import React from 'react';
import { MDBCard, MDBCardBody, MDBCardText, MDBCardTitle, MDBIcon } from 'mdb-react-ui-kit';

const NoData = ({ message, title, containerStyle = {} }) => {
    return (
        <MDBCard
            className="shadow-sm border-0 d-flex align-items-center justify-content-center text-center"
            style={{
                maxWidth: '600px',
                padding: '20px',
                backgroundColor: '#f8f9fa',
                ...containerStyle,
            }}
        >
            <MDBCardBody className="w-100">
                <MDBIcon icon="exclamation-circle" size="3x" className={`text-primary mb-3`} />
                <MDBCardTitle className="fw-bold text-dark">{title || 'No Data Available'}</MDBCardTitle>
                <MDBCardText className="text-muted">{message || 'Try adjusting your filters or check back later for more updates.'}</MDBCardText>
            </MDBCardBody>
        </MDBCard>
    );
};

export default NoData;
