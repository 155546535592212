// Library imports
import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment';
import Masonry, { ResponsiveMasonry } from 'react-responsive-masonry';
import {
    MDBBtn,
    MDBCardBody,
    MDBListGroup,
    MDBListGroupItem,
    MDBModal,
    MDBModalBody,
    MDBModalContent,
    MDBModalDialog,
    MDBModalFooter,
    MDBModalHeader,
    MDBModalTitle,
} from 'mdb-react-ui-kit';
// Component imports
import PropSearchFilter from '../../../components/SearchFilters/PropSearchFilter';
import NoData from '../../../components/NoData';
import LoadingSpinner from '../../../components/LoadingSpinner';
// Styled components
import { GameDetailsHeader, PropTableCard, TeamNameWrapper, TeamLogo, TeamName, GameDate, PropListItem, OddsButtonWrapper } from './styles';
import { LabelValueListGroup } from '../styles';
import { OddButton } from '../GameCards/GameCard/windows/PickOdds/styles';
// Image imports
import defaultImage from '../../../img/logo-icon-gray.png';
// Util imports
import { addPlusSign, createPickId, formatOdd, formatOddButtonJuice } from '../../../utils';
import { PICK_OBJ } from '../../../utils/constants';
// Action imports
import { setUser } from '../../../store/slices/userSlice';
import { addLoader, fetchProps, removeLoader } from '../../../store/slices/appSlice';
// API imports
import { addPick } from '../../../api/picks';

const Props = () => {
    // Redux
    const { sport, teams, loaders, gameData, propData, propName, propCategory } = useSelector(state => state.app);
    const { user } = useSelector(state => state.user);
    const dispatch = useDispatch();
    // Local state
    const [value, setValue] = useState('');
    const [modalShow, setModalShow] = useState(false);
    const [pickData, setPickData] = useState({ ...PICK_OBJ });

    useEffect(() => {
        dispatch(fetchProps());
    }, [sport]);

    const imageLoadError = e => {
        e.target.src = defaultImage;
    };

    const handleCloseModal = () => {
        setModalShow(false);
        setPickData({ ...PICK_OBJ });
    };

    const getPlayersInProp = (awayTeamId, homeTeamId) => {
        var propPlayers = [];
        if (!propData[propCategory]?.[propName]?.players?.length) {
            return propPlayers;
        }
        propPlayers = propData[propCategory][propName].players.filter(player => {
            if (player.teamId !== awayTeamId && player.teamId !== homeTeamId) {
                return false;
            }
            if (!value) {
                return true;
            }
            const teamData = teams.find(team => team.teamId === player.teamId);
            if (player?.name && player.name.toLowerCase().includes(value.toLowerCase())) {
                return true;
            } else if (teamData?.displayName && teamData.displayName.toLowerCase().includes(value.toLowerCase())) {
                return true;
            } else if (teamData?.abbr && teamData.abbr.toLowerCase().includes(value.toLowerCase())) {
                return true;
            } else if (teamData?.fullName && teamData.fullName.toLowerCase().includes(value.toLowerCase())) {
                return true;
            } else {
                return false;
            }
        });
        return propPlayers;
    };

    const renderPlayerList = game => {
        const propPlayers = getPlayersInProp(game.awayTeam.teamId, game.homeTeam.teamId);
        return propPlayers.map((playerPropData, i) => {
            const propType = propData[propCategory][propName].type;
            const oddsButtons = [];
            if (propType === 'single-value') {
                oddsButtons.push(
                    <OddButton
                        onClick={() => {
                            setModalShow(true);
                            setPickData({
                                ...pickData,
                                teamName: `${playerPropData.name} ${playerPropData.value} ${propData[propCategory][propName].name}`,
                                pickTeamId: playerPropData.teamId,
                                pickType: 'prop',
                                pickJuice: playerPropData.value,
                                pickValue: playerPropData.value,
                                gameId: game.gameId,
                                gameTime: game.dateTime,
                            });
                        }}
                        size="sm"
                    >
                        <span className="value">{formatOdd(playerPropData.value)}</span>
                        <span className="juice">{formatOddButtonJuice(playerPropData.value, propType)}</span>
                    </OddButton>,
                );
            }
            if (propType === 'over-under') {
                oddsButtons.push(
                    <OddButton
                        onClick={() => {
                            setModalShow(true);
                            setPickData({
                                ...pickData,
                                teamName: `${playerPropData.name} u${playerPropData.value} ${propData[propCategory][propName].name}`,
                                pickTeamId: playerPropData.teamId,
                                pickType: 'prop',
                                pickJuice: playerPropData.underOdds,
                                pickValue: playerPropData.underOdds,
                                gameId: game.gameId,
                                gameTime: game.dateTime,
                            });
                        }}
                        size="sm"
                    >
                        <span className="value">u{playerPropData.value}</span>
                        <span className="juice">{formatOddButtonJuice(playerPropData.underOdds, propType)}</span>
                    </OddButton>,
                );
                oddsButtons.push(
                    <OddButton
                        onClick={() => {
                            setModalShow(true);
                            setPickData({
                                ...pickData,
                                teamName: `${playerPropData.name} o${playerPropData.value} ${propData[propCategory][propName].name}`,
                                pickTeamId: playerPropData.teamId,
                                pickType: 'prop',
                                pickJuice: playerPropData.overOdds,
                                pickValue: playerPropData.overOdds,
                                gameId: game.gameId,
                                gameTime: game.dateTime,
                            });
                        }}
                        size="sm"
                    >
                        <span className="value">o{playerPropData.value}</span>
                        <span className="juice">{formatOddButtonJuice(playerPropData.overOdds, propType)}</span>
                    </OddButton>,
                );
            }
            return (
                <MDBListGroup key={i}>
                    <PropListItem noBorders>
                        <div style={{ flex: '1' }}>
                            <strong>{playerPropData.name}</strong>
                        </div>
                        <OddsButtonWrapper>{oddsButtons}</OddsButtonWrapper>
                    </PropListItem>
                </MDBListGroup>
            );
        });
    };

    return (
        <div>
            <PropSearchFilter value={value} setValue={setValue} />
            {!propData && !loaders.find(loader => loader === 'prop-data') ? (
                <NoData containerStyle={{ margin: '15px auto' }} />
            ) : loaders.find(loader => loader === 'prop-data') ? (
                <LoadingSpinner />
            ) : (
                <ResponsiveMasonry columnsCountBreakPoints={{ 550: 1, 1100: 2, 1650: 3 }}>
                    <Masonry style={{ padding: '1rem' }} gutter="1rem">
                        {gameData
                            .filter(game => {
                                // We can a not key assigned issue in the map function if we don't filter this out
                                const propPlayers = getPlayersInProp(game.awayTeam.teamId, game.homeTeam.teamId);
                                if (!propPlayers?.length) {
                                    return false;
                                }
                                return true;
                            })
                            .map((game, i) => {
                                return (
                                    <PropTableCard key={i}>
                                        <MDBCardBody>
                                            <GameDate>{moment(game.dateTime).format('MM/DD hh:mm A')}</GameDate>
                                            <GameDetailsHeader>
                                                <TeamNameWrapper>
                                                    <TeamLogo src={game.awayTeam.logo} alt="away team logo" onError={imageLoadError} />
                                                    <TeamName>{game.awayTeam.teamName}</TeamName>
                                                </TeamNameWrapper>
                                                <TeamName style={{ fontWeight: '400' }}>@</TeamName>
                                                <TeamNameWrapper>
                                                    <TeamLogo src={game.homeTeam.logo} alt="home team logo" onError={imageLoadError} />
                                                    <TeamName>{game.homeTeam.teamName}</TeamName>
                                                </TeamNameWrapper>
                                            </GameDetailsHeader>
                                            {renderPlayerList(game)}
                                        </MDBCardBody>
                                    </PropTableCard>
                                );
                            })}
                    </Masonry>
                </ResponsiveMasonry>
            )}
            <MDBModal tabIndex="-1" open={modalShow} onClose={handleCloseModal}>
                <MDBModalDialog centered size="md">
                    <MDBModalContent>
                        <MDBModalHeader>
                            <MDBModalTitle id="contained-modal-title-vcenter">{pickData.teamName}</MDBModalTitle>
                        </MDBModalHeader>
                        <MDBModalBody>
                            <LabelValueListGroup direction="row">
                                <MDBListGroupItem>
                                    <label>Game time:</label>
                                    <span>{moment(pickData.gameTime).format('MM/DD hh:mm A')}</span>
                                </MDBListGroupItem>
                                <MDBListGroupItem>
                                    <label>Value:</label>
                                    <span>{pickData.pickValue}</span>
                                </MDBListGroupItem>
                                <MDBListGroupItem>
                                    <label>Juice:</label>
                                    <span>{addPlusSign(pickData.pickJuice)}</span>
                                </MDBListGroupItem>
                            </LabelValueListGroup>
                        </MDBModalBody>
                        <MDBModalFooter>
                            <MDBBtn color="outline-danger" onClick={handleCloseModal}>
                                Cancel
                            </MDBBtn>
                            <MDBBtn
                                disabled={loaders.find(loader => loader === 'add-pick')}
                                color="success"
                                onClick={async () => {
                                    try {
                                        dispatch(addLoader('add-pick'));
                                        const newPicks = await addPick({
                                            ...pickData,
                                            userId: user.uid,
                                            sport: sport,
                                            pickId: createPickId(),
                                        });
                                        dispatch(setUser({ ...user, picks: newPicks }));
                                    } catch (error) {
                                        console.log(`Error saving pick\n${error}`);
                                    } finally {
                                        dispatch(removeLoader('add-pick'));
                                    }
                                }}
                            >
                                Save Pick
                            </MDBBtn>
                        </MDBModalFooter>
                    </MDBModalContent>
                </MDBModalDialog>
            </MDBModal>
        </div>
    );
};

export default Props;
