// Library imports
import React, { useState, useEffect } from 'react';
import { shape, func, string } from 'prop-types';
import { MDBCard, MDBCol, MDBContainer, MDBListGroupItem, MDBRow } from 'mdb-react-ui-kit';
import { useSelector } from 'react-redux';
import { useTheme } from 'styled-components';
// Component imports
import LineChart from '../../../components/Graphs/LineChart';
import PieChart from '../../../components/Graphs/PieChart';
import Dropdown from '../../../components/DropDown';
import LoadingSpinner from '../../../components/LoadingSpinner';
// API imports
import { getAlgorithmStats } from '../../../api/algorithm';
// Styled components
import { LabelValueListGroup } from '../styles';

const StatGraphs = () => {
    const theme = useTheme();
    // Redux
    const { sport } = useSelector(state => state.app);
    // Local state
    const [activeGraph, setActiveGraph] = useState('moneyline');
    const [activeGraphType, setActiveType] = useState('line');
    const [algoData, setAlgoData] = useState({});
    const [timeframe, setTimeframe] = useState('10');
    const [loading, setLoading] = useState(true);

    const data = algoData[activeGraph];

    const fetchAlgoStats = async () => {
        try {
            setLoading(true);
            const data = await getAlgorithmStats(sport, timeframe);
            setAlgoData(data);
        } catch (error) {
            console.log(`Error getting algo stats data. ${error.message}`);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchAlgoStats();
    }, [sport, timeframe]);

    const generatePieData = () => {
        const wins = data.total.wins;
        const losses = data.total.losses;
        const total = wins + losses;
        return [
            {
                x: `Wins: ${Math.trunc((wins / total) * 100)}%`,
                y: Math.trunc((wins / total) * 100),
            },
            {
                x: `Losses: ${Math.trunc((losses / total) * 100)}%`,
                y: Math.trunc((losses / total) * 100),
            },
        ];
    };

    const generateLineData = () => {
        const lineData = [{ id: activeGraph, data: [] }];
        if (!Object.keys(data.byDate).length) return lineData;
        // Take the last 10 days (if available)
        const last10Days = Object.keys(data.byDate);
        // Loop through the last 10 days and populate the lineData
        last10Days.forEach(date => {
            const winPercent = (data.byDate[date].wins / (data.byDate[date].wins + data.byDate[date].losses)) * 100;
            lineData[0].data.push({ x: date, y: Number(winPercent.toFixed(0)) });
        });
        return lineData;
    };

    return (
        /* This padding matches the <Grid /> padding, we wrap in a container because it is not wrapped in parent component.  
        We can not add the padding to the card */
        <MDBContainer style={{ backgroundColor: theme.colors.silver, padding: '1rem' }} fluid>
            <MDBCard>
                <MDBRow>
                    <MDBCol sm={12} md="auto">
                        <LabelValueListGroup direction="column">
                            <MDBListGroupItem noBorders>
                                <label>Algorithm Type</label>
                                <Dropdown
                                    menuItems={['moneyline', 'powerLine', 'spread', 'dogs']}
                                    value={activeGraph}
                                    setValue={setActiveGraph}
                                    dropdownStyle={{ width: '250px' }}
                                    toggleStyle={{ border: `1px solid ${theme.colors.borderColor}` }}
                                />
                            </MDBListGroupItem>
                            <MDBListGroupItem noBorders>
                                <label>Graph Type</label>
                                <Dropdown
                                    menuItems={['line', 'pie']}
                                    value={activeGraphType}
                                    setValue={setActiveType}
                                    dropdownStyle={{ width: '250px' }}
                                    toggleStyle={{ border: `1px solid ${theme.colors.borderColor}` }}
                                />
                            </MDBListGroupItem>
                        </LabelValueListGroup>
                    </MDBCol>
                    <MDBCol sm={12} md="auto">
                        <LabelValueListGroup style={{ justifyContent: 'space-between' }} direction="column">
                            <MDBListGroupItem noBorders>
                                <label>Record</label>
                                <span style={{ fontSize: '1.55rem', fontWeight: '700' }}>{loading ? '---' : `${data.total?.wins}-${data.total?.losses}`}</span>
                            </MDBListGroupItem>
                            <MDBListGroupItem noBorders>
                                <label>Timeframe (days)</label>
                                <Dropdown
                                    menuItems={['10', '30', '60', 'current_season']}
                                    value={timeframe}
                                    setValue={setTimeframe}
                                    dropdownStyle={{ width: '250px' }}
                                    toggleStyle={{ border: `1px solid ${theme.colors.borderColor}` }}
                                />
                            </MDBListGroupItem>
                        </LabelValueListGroup>
                    </MDBCol>
                </MDBRow>
                {loading ? (
                    <LoadingSpinner />
                ) : activeGraphType === 'line' ? (
                    <LineChart height="35rem" symbol="%" data={generateLineData()} />
                ) : (
                    <PieChart height="35rem" data={generatePieData()} />
                )}
            </MDBCard>
        </MDBContainer>
    );
};

StatGraphs.proptTypes = {
    algoData: shape({}).isRequired,
    timeframe: string.isRequired,
    setTimeframe: func.isRequired,
};

export default StatGraphs;
