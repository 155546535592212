// Library imports
import styled from 'styled-components';
import { MDBContainer, MDBInput } from 'mdb-react-ui-kit';
import { LARGE_SCREEN_WIDTH } from '../../utils/constants';

export const ProfilePageContainer = styled(MDBContainer)`
    padding: 1rem;
`;

export const ProfileContentContainer = styled.div`
    min-height: 50vh;
`;

export const ProfileHeader = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 1rem;
`;

export const ProfileUserName = styled.span`
    font-family: ${({ theme }) => theme.fonts.heading};
    font-weight: 700;
    font-size: 1.65rem;
    color: ${({ theme }) => theme.colors.black};
`;

export const ProfileEmail = styled.span`
    font-family: ${({ theme }) => theme.fonts.body};
    font-size: 1rem;
    color: ${({ theme }) => theme.colors.darkGray};
`;

export const ProfileImage = styled.img`
    height: 85px;
    width: 85px;
`;

export const SearchFilterBar = styled.div`
    padding: 5px;
    background: rgba(0, 0, 0, 0.03);
    display: flex;
    align-items: stretch;
    justify-content: flex-start;
    border: 1px solid rgba(0, 0, 0, 0.125);
    border-bottom: 0px;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    gap: 0.25rem;
    flex-wrap: wrap;
    width: 100%;

    .form-outline {
        flex: 2;
        width: auto !important;
        min-width: 300px !important;
        background: ${({ theme }) => theme.colors.white} !important;
        min-height: 53px;
    }

    @media all and (max-width: ${LARGE_SCREEN_WIDTH}) {
        .form-outline {
            order: 0;
        }
        & .dropdown,
        & .btn {
            order: 1;
        }
    }
`;

export const StyledSearchBar = styled(MDBInput)`
    height: 100%;
    width: 100%;
`;
