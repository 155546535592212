// Library imports
import moment from 'moment';

export const FILTERS = {
    'free-account': {
        id: 'free-account',
        label: 'Free Accounts',
        filter: rec => rec.type === 'free-account',
    },
    'daily-access': {
        id: 'daily-access',
        label: 'Daily Members',
        filter: rec => rec.items[rec.items.length - 1].id === 'daily-access' && rec.type !== 'free-account',
    },
    'weekly-access': {
        id: 'weekly-access',
        label: 'Weekly Members',
        filter: rec => rec.items[rec.items.length - 1].id === 'weekly-access' && rec.type !== 'free-account',
    },
    'monthly-access': {
        id: 'monthly-access',
        label: 'Monthly Members',
        filter: rec => rec.items[rec.items.length - 1].id === 'monthly-access' && rec.type !== 'free-account',
    },
    'yearly-access': {
        id: 'yearly-access',
        label: 'Yearly Members',
        filter: rec => rec.items[rec.items.length - 1].id === 'yearly-access' && rec.type !== 'free-account',
    },
    'lifetime-access': {
        id: 'lifetime-access',
        label: 'Lifetime Members',
        filter: rec => rec.items[rec.items.length - 1].id === 'access' && rec.type !== 'free-account',
    },
    expired: {
        id: 'expired',
        label: 'Expired Packages',
        filter: rec => (rec.endDate && rec.endDate !== 'never' ? moment(rec.endDate).isSameOrBefore(moment()) : false),
    },
    pickpackages: {
        id: 'pickpackages',
        label: 'Pick Packages',
        filter: rec =>
            rec.items[rec.items.length - 1].id === '5050' ||
            rec.items[rec.items.length - 1].id === 'daily' ||
            rec.items[rec.items.length - 1].id === 'play-of-day' ||
            rec.items[rec.items.length - 1].id === 'daily-parlay',
    },
};
