// Library imports
import styled from 'styled-components';
import { MDBCol, MDBIcon } from 'mdb-react-ui-kit';
// Constant imports
import { POWER_BREAK_POINT } from './constants';

export const PowerCol = styled(MDBCol)`
    flex: 1 1 33.333%;
`;

export const TeamSectionContainer = styled.div`
    display: flex;
    flex-direction: column;
    position: relative;
`;

export const TeamNameWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    @media (max-width: ${POWER_BREAK_POINT}) {
        align-items: ${p => (p.side === 'left' ? 'flex-start' : 'flex-end')};
    }
    justify-content: flex-start;
`;

export const TeamLogo = styled.img`
    height: 40px;
    width: 40px;
    margin-bottom: -8px;
`;

export const PowerTeamId = styled.span`
    display: block;
    font-size: 10px;
    color: #888;
    margin-top: -10px;
    margin-bottom: 10px;
`;

export const PowerDataContainer = styled.div`
    display: flex;
    flex-direction: column;
`;

export const PowerItemContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 1rem;
`;

export const PowerItemLabel = styled.span`
    font-weight: 900;
    color: ${({ theme }) => theme.colors.black};
    margin-bottom: 10px;
`;

export const PowerRankArrow = styled(MDBIcon)`
    color: ${({ theme }) => theme.colors.primaryDark};
    font-size: 24px;
    background: ${({ theme }) => theme.colors.white};
    border-radius: 50%;
    @media (max-width: ${POWER_BREAK_POINT}) {
        font-size: 16px;
        margin: 0px 5px;
    }
`;
