// Library imports
import styled from 'styled-components';
import { MDBBtn, MDBListGroup, MDBNavbar } from 'mdb-react-ui-kit';
// Constants
import { POWER_BREAK_POINT } from './GameCards/GameCard/windows/PowerRank/constants';
import { LARGE_SCREEN_WIDTH, SMALL_SCREEN_WIDTH } from '../../utils/constants';

export const ToolsNavbar = styled(MDBNavbar)`
    padding: 0.75rem 0rem;
    box-shadow: none;
    border-bottom: 1px solid ${({ theme }) => theme.colors.borderColor};
    z-index: ${({ drawerOpen }) => (drawerOpen ? 0 : 9999999)};
`;

export const TourButton = styled(MDBBtn)`
    display: inline-block !important;
    position: absolute;
    right: 25px !important;
    top: 12px;
    @media (max-width: ${LARGE_SCREEN_WIDTH}) {
        right: 15px !important;
        top: 6px;
    }
`;

export const LabelValueListGroup = styled(MDBListGroup)`
    .list-group-item {
        display: flex !important;
        flex-direction: ${({ direction }) => (direction ? direction : 'column')} !important;
        align-items: ${({ direction }) => (direction === 'row' ? 'center' : 'flex-start')} !important;
        ${({ theme, silverBg, coloredList }) => !coloredList && `background-color: ${silverBg === 'true' ? theme.colors.silver : theme.colors.white};`}
        padding: 10px 15px;
        border-radius: 0px;

        label,
        span {
            font-size: 0.95rem;
        }

        span {
            ${({ theme, coloredList }) => !coloredList && `color: ${theme.colors.black};`}
            font-weight: 600;
        }

        label {
            width: auto;
            display: block;
            margin-bottom: 0px;
            font-weight: bold;
            margin-right: auto;
            ${({ theme, coloredList }) => !coloredList && `color: ${theme.colors.darkGray};`}
            @media (max-width: ${POWER_BREAK_POINT}) {
                margin-right: ${props => (props.rightJustify ? '0px' : 'auto')} !important;
            }
        }

        @media (max-width: ${SMALL_SCREEN_WIDTH}) {
            span {
                font-size: 0.85rem;
            }
        }

        @media (max-width: ${POWER_BREAK_POINT}) {
            flex-direction: ${props => (props.responsive ? 'column' : props.direction ? props.direction : 'column')} !important;
            align-items: ${props =>
                props.rightJustify ? 'flex-end' : props.responsive ? 'flex-start' : props.direction === 'row' ? 'center' : 'flex-start'} !important;
        }

        &:hover {
            cursor: ${({ hoverable }) => (hoverable ? 'pointer' : 'default')};
            background-color: ${({ theme }) => theme.colors.lightGray};
        }
    }
`;
