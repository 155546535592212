// Library imports
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { MDBBtn, MDBInput, MDBTable, MDBTableBody, MDBTableHead } from 'mdb-react-ui-kit';
// Component imports
import Dropdown from '../../../components/DropDown';
// Action imports
import { setUser } from '../../../store/slices/userSlice';
// Styled components
import { ProfileContentContainer, SearchFilterBar } from '../styles';
// Util imports
import { SPORTS } from '../../../utils/constants';
import { formatPickJuice, formatPickValue, sortPicks } from '../../../utils';
// API imports
import { deletePick, updatePick } from '../../../api/picks';

const Picks = () => {
    //Redux
    const { user } = useSelector(state => state.user);
    const dispatch = useDispatch();
    // Local state
    const [loading, setLoading] = useState(false);
    const [pickArr, setPickArr] = useState([]);
    const [pickFilter, setPickFilter] = useState('all');
    const [showDate, setShowDate] = useState('Pending');
    const [value, setValue] = useState('');

    useEffect(() => {
        var picks = user.picks;
        if (pickFilter !== 'all') {
            setPickArr(sortPicks(picks, 'asc').filter(pick => pick.sport === pickFilter));
        } else {
            setPickArr(sortPicks(picks, 'asc'));
        }
    }, [pickFilter, user.picks]);

    const handleSavePick = async (pickId, result) => {
        try {
            setLoading(true);
            var pickDetails = pickArr.find(pick => pick.pickId === pickId);
            pickDetails = { ...pickDetails, result: result };
            const newPicks = await updatePick(pickDetails);
            dispatch(setUser({ ...user, picks: newPicks }));
        } catch (error) {
            console.log(`Error saving pick edit. ${error.message}`);
        } finally {
            setLoading(false);
        }
    };

    const handleDeletePick = async pickId => {
        try {
            setLoading(true);
            const newPicks = await deletePick(pickId);
            dispatch(setUser({ ...user, picks: newPicks }));
        } catch (error) {
            console.log(`Error deleting pick. ${error.message}`);
        } finally {
            setLoading(false);
        }
    };

    const createDateList = () => {
        const dateSet = new Set();
        for (const game of pickArr) {
            const gameDate = moment(game.gameTime).format('MM-DD-YYYY');
            dateSet.add(gameDate);
        }
        const dateList = Array.from(dateSet).slice(0, 5).reverse();
        return ['Pending', ...dateList];
    };

    return (
        <ProfileContentContainer>
            <SearchFilterBar>
                <Dropdown
                    value={pickFilter}
                    setValue={setPickFilter}
                    menuItems={['all', ...SPORTS]}
                    caption="Filter by sport"
                    dropdownStyle={{
                        flex: 1,
                        maxWidth: '250px',
                        border: '1px solid #ddd',
                    }}
                    toggleStyle={{ backgroundColor: '#fff' }}
                />
                <Dropdown
                    value={showDate}
                    setValue={setShowDate}
                    menuItems={createDateList()}
                    caption="Filter by date"
                    dropdownStyle={{
                        flex: 1,
                        maxWidth: '250px',
                        border: '1px solid #ddd',
                    }}
                    toggleStyle={{ backgroundColor: '#fff' }}
                />
                <MDBInput
                    style={{ flex: 3, background: '#fff', height: '100%' }}
                    aria-label="Search"
                    id="pick-search"
                    value={value}
                    onChange={e => setValue(e.target.value)}
                />
            </SearchFilterBar>
            <MDBTable striped responsive>
                <MDBTableHead dark>
                    <tr>
                        <th>Result</th>
                        <th>Play</th>
                        <th>Value</th>
                        <th>Units</th>
                        <th>Delete</th>
                    </tr>
                </MDBTableHead>
                <MDBTableBody>
                    {pickArr
                        .filter(pick => {
                            if (value) {
                                if (!pick.teamName.toLowerCase().includes(value.toLowerCase()) && !pick.sport.toLowerCase().includes(value.toLowerCase())) {
                                    return false;
                                }
                            }
                            if (pick.sport === pickFilter || pickFilter === 'all') {
                                if (showDate === 'Pending' && pick.result === 'pending') {
                                    return true;
                                }
                                if (moment(pick.gameTime).isSame(showDate, 'D')) {
                                    return true;
                                }
                            }
                            return false;
                        })
                        .map(pick => {
                            var rowClass = 'default';
                            if (pick.result === 'win') {
                                rowClass = 'table-success';
                            }
                            if (pick.result === 'loss') {
                                rowClass = 'table-danger';
                            }
                            return (
                                <tr className={rowClass}>
                                    <td>
                                        <Dropdown
                                            value={String(pick.result).toLowerCase()}
                                            setValue={value => handleSavePick(pick.pickId, value)}
                                            menuItems={['pending', 'win', 'loss']}
                                            disabled={loading}
                                        />
                                    </td>
                                    <td>
                                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                                            <span style={{ fontSize: '12px', fontWeight: '400', color: '#666' }}>
                                                {moment(pick.gameTime).format('MM/DD hh:mm A')}
                                            </span>
                                            <span style={{ fontWeight: 'bold', color: '#444' }}>{pick.teamName}</span>
                                            <span
                                                style={{
                                                    fontSize: '12px',
                                                    fontWeight: '400',
                                                    color: '#666',
                                                }}
                                            >
                                                {pick.sport.toUpperCase()}
                                            </span>
                                        </div>
                                    </td>
                                    <td>
                                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                            <span style={{ fontWeight: 'bold', color: '#444' }}>{formatPickValue(pick)}</span>
                                            <span style={{ fontWeight: '400', color: '#888', marginLeft: '8px', fontSize: '14px' }}>
                                                {formatPickJuice(pick)}
                                            </span>
                                        </div>
                                    </td>
                                    <td>{`${pick.units}u`}</td>
                                    <td>
                                        <MDBBtn onClick={async () => await handleDeletePick(pick.pickId)} disabled={loading} color="danger">
                                            Delete
                                        </MDBBtn>
                                    </td>
                                </tr>
                            );
                        })}
                </MDBTableBody>
            </MDBTable>
        </ProfileContentContainer>
    );
};

export default Picks;
