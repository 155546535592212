import { get, patch, post } from './APIHelper';

export const getGame = async (sport, gameId, db) => {
    return get(`games/game?sport=${sport}&gameId=${gameId}&db=${db}`);
};

export const getUpcomingGames = async (sport, accountType) => {
    return get(`games/upcoming?sport=${sport}&accountType=${accountType}`);
};

export const getCompletedGames = async (sport, timeframe, filter) => {
    return get(`games/completed?sport=${sport}&timeframe=${timeframe}&filter=${filter}`);
};

export const getKeyFactors = async sport => {
    return get(`keyfactors?sport=${sport}`);
};

export const updateGames = async sport => {
    return patch(`games/upcoming?sport=${sport}`);
};

export const saveGame = async (game, sport, db) => {
    return post(`games/saveGame`, { game, sport, db });
};

export const searchBuilderQuery = async (data, sport) => {
    return get(`games/searchBuilder?data=${encodeURIComponent(JSON.stringify(data))}&sport=${sport}`);
};
