// Library imports
import React from 'react';
import { useTheme } from 'styled-components';
import { MDBCol, MDBInput } from 'mdb-react-ui-kit';
import { func, string } from 'prop-types';
// Component imports
import Dropdown from '../DropDown';
// Styled components
import { SearchFilterContainer, SearchFilterRow, SearchWrapper } from './styles';

const TrendSearchFilter = ({ value, setValue }) => {
    const theme = useTheme();

    return (
        <SearchFilterContainer fluid>
            <SearchFilterRow className="g-0">
                <MDBCol md={12} lg={2} className="first-col">
                    <Dropdown
                        caption="Timeframe"
                        toggleStyle={{
                            background: theme.colors.white,
                            borderRadius: 0,
                            borderTopLeftRadius: 999,
                            borderBottomLeftRadius: 999,
                        }}
                        value="current_season"
                        setValue={selectedKey => ''}
                        menuItems={['current_season']}
                    />
                </MDBCol>
                <MDBCol md={12} lg={10} className="search-col">
                    <SearchWrapper style={{ borderTopRightRadius: 999, borderBottomRightRadius: 999 }}>
                        <MDBInput
                            type="search"
                            placeholder="Start typing a trend name..."
                            aria-label="Search"
                            id="card-search"
                            value={value}
                            onChange={e => setValue(e.target.value)}
                        />
                    </SearchWrapper>
                </MDBCol>
            </SearchFilterRow>
        </SearchFilterContainer>
    );
};

TrendSearchFilter.propTypes = {
    value: string,
    setValue: func.isRequired,
};

export default TrendSearchFilter;
