// Library imports
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Helmet } from 'react-helmet';
import { MDBCollapse, MDBContainer, MDBNavbarNav, MDBNavbarToggler, MDBIcon, MDBNavbarItem, MDBNavbarLink } from 'mdb-react-ui-kit';
import Skeleton from 'react-loading-skeleton';
// Component imports
import Picks from './Picks';
import Settings from './Settings';
import VIPs from './VIP';
import API from './API';
import Investor from './Investor';
import CreatePick from './CreatePick';
import Sidebar from '../Sidebar';
import LoadingSpinner from '../../components/LoadingSpinner';
import UpgradeTooltip from '../../components/UpgradeTooltip';
import LayoutWithHeader from '../../components/Layouts/LayoutWithHeader';
// Util imports
import { capitalize } from '../../utils';
import { PROFILE_PAGES } from './constants';
// Firebase imports
import firebase from 'firebase';
// Action imports
import { setDrawer, setSubPage } from '../../store/slices/appSlice';
import { fetchUser } from '../../store/slices/userSlice';
// Styled components
import { ProfileEmail, ProfileHeader, ProfileUserName, ProfileImage, ProfilePageContainer } from './styles';
import { ToolsNavbar } from '../App/styles';
// Image imports
import avatar from '../../img/logo-icon.png';

const Profile = () => {
    // Redux
    const dispatch = useDispatch();
    const { subPage, openDrawer } = useSelector(state => state.app);
    const { user, userLoading } = useSelector(state => state.user);
    // Local state
    const [navbarOpen, setNavbarOpen] = useState(false);

    useEffect(() => {
        if (!PROFILE_PAGES.find(page => page.id === subPage)) {
            dispatch(setSubPage('picks'));
        }
        dispatch(setDrawer(''));
        dispatch(fetchUser());
    }, []);

    return (
        <LayoutWithHeader contentStyle={{ minHeight: '65vh' }} page="profile" shadow={false}>
            <Helmet>
                <title>Profile | Line Prophet</title>
                <meta
                    name="description"
                    content="Line Prophet gives you everything you need to gain an edge over the books. Our supercomputer gives an algorithm-generated prediction for every game. We follow the most relevant trends using the most up to date data."
                />
                <meta name="viewport" content="width=device-width, initial-scale=1.0" />

                {/* Open Graph / Facebook */}
                <meta property="og:type" content="website" />
                <meta property="og:title" content="Profile | Line Prophet" />
                <meta
                    property="og:description"
                    content="Line Prophet gives you everything you need to gain an edge over the books. Our supercomputer gives an algorithm-generated prediction for every game. We follow the most relevant trends using the most up to date data."
                />
                <meta property="og:url" content="https://www.lineprophet.com/" />
                <meta property="og:image" content="https://lineprophet.com/static/media/logo-icon.3f56cee251416e22eb67.png" />

                {/* Twitter */}
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:title" content="Profile | Line Prophet" />
                <meta
                    name="twitter:description"
                    content="Line Prophet gives you everything you need to gain an edge over the books. Our supercomputer gives an algorithm-generated prediction for every game. We follow the most relevant trends using the most up to date data."
                />
                <meta name="twitter:image" content="https://lineprophet.com/static/media/logo-icon.3f56cee251416e22eb67.png" />
                <meta name="twitter:site" content="@LineProphetLLC" />
                <meta name="twitter:creator" content="@LineProphetLLC" />

                {/* Favicon */}
                <link rel="icon" href="/favicon.ico" />
            </Helmet>
            <ToolsNavbar expand="lg" drawerOpen={user.reciept?.type === 'free-account' || openDrawer}>
                <MDBContainer fluid>
                    <MDBNavbarToggler type="button" aria-expanded="false" aria-label="Toggle navigation" onClick={() => setNavbarOpen(!navbarOpen)}>
                        <MDBIcon icon="bars" fas /> <span style={{ fontWeight: '700', marginLeft: '0.25rem' }}>{capitalize(subPage)}</span>
                    </MDBNavbarToggler>
                    <MDBCollapse className="justify-content-center w-100" navbar open={navbarOpen}>
                        <MDBNavbarNav>
                            {PROFILE_PAGES.filter(page => (page.admin ? user.admin : true)).map((page, index) => {
                                if (!page.free && user.reciept?.type === 'free-account') {
                                    return (
                                        <UpgradeTooltip
                                            key={index}
                                            children={
                                                <MDBNavbarItem>
                                                    <MDBNavbarLink>
                                                        <MDBIcon icon={page.icon} /> {page.title}
                                                    </MDBNavbarLink>
                                                </MDBNavbarItem>
                                            }
                                        />
                                    );
                                }
                                return (
                                    <MDBNavbarItem key={index}>
                                        <MDBNavbarLink
                                            onClick={() => {
                                                setNavbarOpen(false);
                                                if (page.id === 'logout') {
                                                    firebase.auth().signOut();
                                                } else {
                                                    dispatch(setSubPage(page.id));
                                                }
                                            }}
                                            active={subPage === page.id}
                                            disabled={page.fullScreen && windowWidth < ADVANCED_SEARCH_SCREEN_SIZE}
                                        >
                                            <MDBIcon icon={page.icon} /> {page.title}
                                        </MDBNavbarLink>
                                    </MDBNavbarItem>
                                );
                            })}
                        </MDBNavbarNav>
                    </MDBCollapse>
                </MDBContainer>
            </ToolsNavbar>
            <ProfilePageContainer fluid>
                <ProfileHeader>
                    {!userLoading && <ProfileImage src={avatar} />}
                    <div style={{ display: 'flex', flexDirection: 'column', marginLeft: '0.5rem' }}>
                        <ProfileUserName>{userLoading ? <Skeleton inline={false} width={200} /> : user.displayName}</ProfileUserName>
                        <ProfileEmail>{userLoading ? <Skeleton inline={false} width={200} /> : user.email}</ProfileEmail>
                    </div>
                </ProfileHeader>
                {userLoading ? (
                    <LoadingSpinner />
                ) : subPage === 'picks' ? (
                    <Picks key="picks" />
                ) : subPage === 'investor' ? (
                    <Investor key="investor" />
                ) : subPage === 'settings' ? (
                    <Settings key="settings" />
                ) : subPage === 'vips' ? (
                    <VIPs key="vips" />
                ) : subPage === 'api' ? (
                    <API key="api-admin" />
                ) : subPage === 'create-pick' ? (
                    <CreatePick key="create-pick" />
                ) : (
                    <></>
                )}
            </ProfilePageContainer>
            <Sidebar tabs={['vip']} />
        </LayoutWithHeader>
    );
};

export default Profile;
