// Library imports
import React, { useState } from 'react';
import { MDBDropdown, MDBIcon } from 'mdb-react-ui-kit';
import { arrayOf, bool, element, func, shape, string } from 'prop-types';
// Styled components
import { ClearButton, MenuContainer, MenuItem, SearchBar, Toggle } from './styles';
// Util imports
import { SPORTS } from '../../utils/constants';

const formatValue = value => {
    if (!value) {
        return 'Select...';
    }
    if (SPORTS.includes(value)) {
        return String(value).toUpperCase();
    }
    if (value === 'all') {
        return 'All';
    }
    return value;
};

const Dropdown = ({
    dropdownStyle = {},
    toggleStyle = {},
    menuStyle = {},
    value,
    setValue,
    menuItems = [],
    variant = 'light',
    caption = '',
    searchable = false,
    clearable = false,
    icon = null,
    disabled = false,
}) => {
    const [searchValue, setSearchValue] = useState('');
    return (
        <MDBDropdown style={{ width: '100%', ...dropdownStyle }} disabled={disabled}>
            <Toggle color={variant} clearable={clearable} style={{ ...toggleStyle }}>
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'flex-start',
                    }}
                >
                    <div style={{ display: 'flex', alignItems: 'center', gap: '0.25rem' }}>
                        {icon} {formatValue(value)}
                    </div>
                    {caption ? <span style={{ fontSize: 10 }}>{caption}</span> : <></>}
                </div>
            </Toggle>
            {clearable && (
                <ClearButton variant="outline-danger" onClick={() => setValue('')}>
                    <MDBIcon icon="eraser" />
                </ClearButton>
            )}
            <MenuContainer clearable={clearable} style={{ ...menuStyle }}>
                {
                    // TODO - Add search bar
                }
                {menuItems
                    .filter(item => !searchValue || item.toLowerCase().includes(searchValue))
                    .map((item, i) => {
                        return (
                            <MenuItem
                                onClick={() => {
                                    setSearchValue('');
                                    setValue(item);
                                }}
                                lastItem={menuItems.length - 1 === i}
                                className={value === item ? 'active' : ''}
                            >
                                {formatValue(item)}
                            </MenuItem>
                        );
                    })}
            </MenuContainer>
        </MDBDropdown>
    );
};

Dropdown.propTypes = {
    dropdownStyle: shape({}),
    toggleStyle: shape({}),
    menuStyle: shape({}),
    value: string,
    setValue: func.isRequired,
    menuItems: arrayOf(string),
    variant: string,
    caption: string,
    searchable: bool,
    clearable: bool,
    icon: element,
    disabled: bool,
};

export default Dropdown;
