// Library imports
import styled from 'styled-components';
import { MDBCol, MDBContainer } from 'mdb-react-ui-kit';
import { Calendar } from 'react-calendar';
// Util imports
import { XL_SCREEN_WIDTH } from '../../utils/constants';

export const SectionContainer = styled(MDBContainer)`
    padding: 6rem 10rem;
    background-color: ${({ variant, theme }) =>
        variant === 'white' ? theme.colors.white : variant === 'silver' ? theme.colors.silver : theme.colors.primaryDark};
    @media all and (max-width: ${XL_SCREEN_WIDTH}) {
        padding: 6rem 2rem;
    }
`;

export const ContentColumn = styled(MDBCol)`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
`;

export const TopLine = styled.div`
    color: #f00946;
    font-size: 16px;
    font-weight: bold;
    letter-spacing: 1.4px;
    line-height: 16px;
    text-transform: uppercase;
    margin-bottom: 16px;
`;

export const Heading = styled.h1`
    font-family: ${({ theme }) => theme.fonts.heading};
    font-weight: 900 !important;
    margin-bottom: 1rem !important;
    text-align: center;
    font-size: ${props => (props.main === 'true' ? '3.75rem' : '2.5rem')} !important;
    line-height: ${props => (props.main === 'true' ? '3.40rem' : '2.5rem')} !important;
    color: ${props => (props.variant === 'dark' ? '#1c2237' : '#f7f8fa')};
    ${props => props.color && `letter-spacing: -.025em;`}
    @media (max-width: 991px) {
        font-size: ${props => (props.main === 'true' ? '3rem' : '2.5rem')} !important;
        line-height: ${props => (props.main === 'true' ? '3rem' : '2.5rem')} !important;
        margin-bottom: 1rem !important;
    }
`;

export const Description = styled.p`
    color: ${props => (props.variant === 'dark' ? '#1c2237' : '#f7f8fa')};
    font-family: ${({ theme }) => theme.fonts.body};
    font-size: 1.3rem;
    letter-spacing: 0.04rem;
    line-height: 1.7;
    text-align: center;
`;

export const ButtonContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
`;

export const ImageWrapper = styled.div`
    width: 100%;
    max-height: 750px; /* Set the max height you want */
    overflow: hidden; /* Ensures cropping */
    display: flex;
    justify-content: center;
    align-items: flex-start;
`;

export const SectionImage = styled.img`
    display: block;
    width: 100%;
    height: auto; /* Keeps the original aspect ratio */
    @media (max-width: 990px) {
        margin-top: 25px !important;
    }
`;

export const FormattedCalendar = styled(Calendar)`
    & .day-number {
        font-family: ${({ theme }) => theme.fonts.heading};
        font-size: clamp(0.8rem, 1vw, 1rem);
        font-weight: 700;
    }
    & .unit-total {
        font-family: ${({ theme }) => theme.fonts.body};
        font-weight: 500;
        font-size: clamp(0.8rem, 1vw, 1rem);
    }
    & button {
        border: none;
        padding: 0;
    }
`;
