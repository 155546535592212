// Library imports
import styled from 'styled-components';
import Tooltip from 'react-bootstrap/Tooltip';

export const UpgradeTooltipContainer = styled(Tooltip)`
    .tooltip-inner {
        padding: 10px;
        background: #fff;
        border: 1px solid #ddd;
        border-left: 0px;
    }
    .arrow:before {
        border-left-color: #fff;
        border-right-color: #fff;
    }
    span {
        color: #000;
        font-weight: bold;
    }
`;
