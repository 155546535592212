// Library imports
import React from 'react';
import { ResponsiveLine } from '@nivo/line';
import { useTheme } from 'styled-components';
import { shape, string, arrayOf } from 'prop-types';

const LineChart = ({ data = [], symbol = '', height = '25rem' }) => {
    const theme = useTheme();
    // Flatten all y values from all series in the dataset
    const yValues = data.flatMap(series => series.data.map(point => point.y));
    // Calculate the average Y value
    const averageY = yValues.length > 0 ? yValues.reduce((sum, y) => sum + y, 0) / yValues.length : 0;

    return (
        <div style={{ height }}>
            <ResponsiveLine
                data={data}
                margin={{ top: 20, right: 20, bottom: 50, left: 70 }}
                xScale={{ type: 'point', paddingInner: 0.5 }}
                yScale={{
                    type: 'linear',
                    min: '0',
                    max: 'auto',
                    stacked: true,
                    reverse: false,
                }}
                yFormat=" >-.2f"
                axisTop={null}
                axisRight={null}
                axisBottom={{
                    orient: 'bottom',
                    tickSize: 5,
                    tickPadding: 5,
                    tickRotation: -25,
                    legend: '',
                    legendOffset: 40,
                    legendPosition: 'middle',
                    truncateTickAt: 0,
                }}
                axisLeft={{
                    orient: 'left',
                    tickSize: 5,
                    tickPadding: 5,
                    tickRotation: 0,
                    legendOffset: -40,
                    legendPosition: 'middle',
                    format: d => `${d}${symbol}`,
                }}
                pointSize={10}
                pointColor={{ from: 'color', modifiers: [['brighter', 0.3]] }}
                pointBorderWidth={2}
                pointBorderColor={{ from: 'serieColor' }}
                pointLabelYOffset={-12}
                useMesh={true}
                enableGridX={false}
                enableGridY={true}
                enableArea={true}
                colors={theme.colors.primary}
                tooltip={({ point }) => (
                    <div
                        style={{
                            padding: '5px 10px',
                            color: theme.colors.black,
                            background: theme.colors.white,
                            border: `1px solid ${theme.colors.primary}`,
                        }}
                    >
                        {point.data.y.toLocaleString()}
                        {symbol} on {point.data.x}
                    </div>
                )}
                markers={[
                    {
                        axis: 'y',
                        value: averageY,
                        lineStyle: {
                            stroke: theme.colors.red,
                            strokeWidth: 2,
                            strokeDasharray: '4 4',
                        },
                        legend: `Avg: ${averageY.toFixed(2)}${symbol}`,
                        legendPosition: 'top-left',
                    },
                ]}
            />
        </div>
    );
};

LineChart.propTypes = {
    data: arrayOf(shape({})),
    symbol: string,
    height: string,
};

export default LineChart;
