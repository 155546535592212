// Library imports
import React, { useState } from 'react';
// Component imports
import StatGraphs from './StatGraphs';
import HistoryCards from './HistoryCards';
import TabNav from '../../../components/TabNav';

const Algorithm = () => {
    // Local state
    const [tab, setTab] = useState('stats');

    return (
        <div>
            <TabNav activeTab={tab} setTab={setTab} tabs={['stats', 'history']} containerStyle={{ width: '201px', margin: '0 auto' }} />
            {tab === 'stats' ? <StatGraphs /> : <HistoryCards />}
        </div>
    );
};

export default Algorithm;
