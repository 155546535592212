// Library imports
import styled from 'styled-components';

export const GameCardWrapper = styled.div`
    background: ${({ theme }) => theme.colors.white};
    min-width: 375px;
    max-width: 550px;
    border: 1px solid #ddd;
    border-radius: 5px;
    box-shadow: 5px 5px 5px #ddd;

    .popover-btn {
        cursor: pointer !important;
        background: ${({ theme }) => theme.colors.silver} !important;
        color: ${({ theme }) => theme.colors.silver} !important;
    }
`;

export const GameCardHeaderWrapper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 10px;
    border-bottom: 1px solid #dedede;
    background-color: #fff;
    position: relative;
`;

export const GameCardTitleWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
`;

export const GameCardTitle = styled.span`
    font-size: 18px;
    font-weight: bold;
`;

export const GameCardSubtitle = styled.span`
    font-size: 14px;
`;

export const BadgeContainer = styled.div`
    position: absolute;
    top: 38px;
    left: 120px;
    display: flex;
    flex-direction: row;
    align-items: center;
`;

export const HeaderButtonContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 0.5rem;
`;

export const GameCardContentWrapper = styled.div`
    box-sizing: border-box;
    position: relative;
    display: flex;
    height: 275px;
    flex-direction: row;
    justify-content: flex-start;
`;

export const Toolbar = styled.div`
    background-image: linear-gradient(120deg, #fdfbfb 0%, #ebedee 100%);
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
    border-top: 1px solid ${({ theme }) => theme.colors.borderColor};
`;

export const ToolbarButton = styled.button`
    flex: 1;
    background-color: ${({ active, theme, disabled }) =>
        disabled ? theme.colors.silver : active === 'true' ? theme.colors.primary : theme.colors.white} !important;
    color: ${({ active, theme, disabled }) =>
        disabled ? theme.colors.darkGray : active === 'true' ? theme.colors.white : theme.colors.primaryDark} !important;
    border: 0px;
    padding: 0.65rem 1rem;
    border-right: 1px solid ${({ theme }) => theme.colors.borderColor};
    border-bottom: 1px solid ${({ theme }) => theme.colors.borderColor};
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 0.15rem;

    span {
        white-space: nowrap;
    }

    &:hover {
        cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
        background-color: ${({ theme, disabled }) => (disabled ? theme.colors.silver : theme.colors.primary)} !important;
        color: ${({ theme, disabled }) => (disabled ? theme.colors.darkGray : theme.colors.white)} !important;
    }

    &:disabled {
        pointer-events: none;
        opacity: 0.6;
    }
`;
