// Library imports
import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { MDBBtn, MDBIcon } from 'mdb-react-ui-kit';
import Faq from 'react-faq-component';
import { useTheme } from 'styled-components';
// Component imports
import LayoutWithHeader from '../components/Layouts/LayoutWithHeader';
import Section from '../components/Section';
import Testimonials from '../components/Testimonials';
import FeatureSection from '../components/FeatureSection';
// Util imports
import { scrollToTop } from '../utils';

const About = () => {
    const theme = useTheme();
    // Routing
    const history = useHistory();

    useEffect(() => scrollToTop(), []);

    return (
        <LayoutWithHeader page="about">
            <Helmet>
                <title>About | Line Prophet</title>
                <meta
                    name="description"
                    content="Line Prophet gives you everything you need to gain an edge over the books. Our supercomputer gives an algorithm-generated prediction for every game. We follow the most relevant trends using the most up to date data."
                />
                <meta name="viewport" content="width=device-width, initial-scale=1.0" />

                {/* Open Graph / Facebook */}
                <meta property="og:type" content="website" />
                <meta property="og:title" content="About | Line Prophet" />
                <meta
                    property="og:description"
                    content="Line Prophet gives you everything you need to gain an edge over the books. Our supercomputer gives an algorithm-generated prediction for every game. We follow the most relevant trends using the most up to date data."
                />
                <meta property="og:url" content="https://www.lineprophet.com/" />
                <meta property="og:image" content="https://lineprophet.com/static/media/logo-icon.3f56cee251416e22eb67.png" />

                {/* Twitter */}
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:title" content="About | Line Prophet" />
                <meta
                    name="twitter:description"
                    content="Line Prophet gives you everything you need to gain an edge over the books. Our supercomputer gives an algorithm-generated prediction for every game. We follow the most relevant trends using the most up to date data."
                />
                <meta name="twitter:image" content="https://lineprophet.com/static/media/logo-icon.3f56cee251416e22eb67.png" />
                <meta name="twitter:site" content="@LineProphetLLC" />
                <meta name="twitter:creator" content="@LineProphetLLC" />

                {/* Favicon */}
                <link rel="icon" href="/favicon.ico" />
            </Helmet>
            <Section
                backgroundColor="white"
                topLine="Focused Algorithms and Data"
                headline="Line Prophet provides an in-depth look at every game."
                description="Access comprehensive game data effortlessly with our unique matchup cards, providing a distinctive insight into each game. Each window is a gateway to a wealth of matchup data. Immerse yourself in the experience by trying out our demo card - your key to unlocking unparalleled insights."
                buttons={[
                    <MDBBtn onClick={() => history.push('/packages')} color="primary" size="lg">
                        <MDBIcon className="me-2" icon="sign-in-alt" />
                        Get Started
                    </MDBBtn>,
                ]}
                contentSide="right"
                contentType="card"
            />
            <Section
                backgroundColor="silver"
                topLine="Trends and Situational Data"
                headline="Take your capping to the next level."
                description="Beyond head-to-head statistics, Line Prophet's system incorporates trends and situational data, adding depth to your analysis. Unlock the full spectrum of information, empowering you to make informed decisions. Access this wealth of data firsthand to enhance your understanding of each scenario and maximize your sports betting strategy."
                buttons={[
                    <MDBBtn onClick={() => history.push('/packages')} color="primary" size="lg">
                        <MDBIcon className="me-2" icon="sign-in-alt" />
                        Get Started
                    </MDBBtn>,
                ]}
                contentSide="left"
                contentType="trend"
            />
            <FeatureSection
                title="FAQs"
                subTitle="Learn more about what an all access package provides.  We are so much more than just expert pick selections.  Our data and insights truly provide a one-of-a-kind look at every matchup"
                bgColor={theme.colors.white}
            >
                <Faq
                    data={{
                        rows: [
                            {
                                title: 'What kind of data does the platform provide?',
                                content: `Our comprehensive insights provide a 360 degree view of each game on the board.  Our game cards provide key factor analysis, line movement data, situational data, top headlines, AI predictions, and so much more.  On top of that you will also have access to our unique trend calculator that can be applied to the daily board.  All the information you need to quickly and easily analyze each game is provided on both our web and mobile apps.`,
                            },
                            {
                                title: 'Is this just a capping service?',
                                content: `While every package includes hand selected picks from our experts, access to the platform provides so much more.  Dive into each game with our matchup insights, unique trends, and community discussions.  You will also be invited to join our private Discord where our experts and community are there to answer all of your questions.`,
                            },
                            {
                                title: 'Ho w do you track VIP results?',
                                content: `Once you log in you will have access to our one of a kind VIP insights panel.  This includes a look into our daily, weekly, monthly, and all time play results.  Our custom filters allow you to take an in-depth look at how our system performs across many different parameters.  Filter by sport, unit size, time of year, and so much more.`,
                            },
                            {
                                title: 'What comes with an access package?',
                                content:
                                    'With an Access Package you will get all of the benefits the Line Prophet platform has to offer.  With your account you will have access to both our web and mobile apps, private Discord, and VIP Twitter community.  Never miss a play ',
                            },
                            {
                                title: 'How are picks delivered?',
                                content:
                                    'Expert picks can be found via our web and mobile apps with an access package.  All plays are automatically tweeted out to our VIP Twitter page via our bots right as they are entered by one of our experts.  With a pick package plays will be delivered to your email each day.',
                            },
                        ],
                    }}
                    styles={{
                        bgColor: 'transparent',
                        titleTextColor: '#000',
                        rowTitleColor: '#000',
                        padding: '60px 0px',
                        rowContentColor: '#666',
                    }}
                />
            </FeatureSection>
            <Section
                backgroundColor="silver"
                topLine="Honesty, transparency, results"
                headline="VIP Picks & Transparency."
                description="Explore our VIP Picks and Transparency Drawer, offering a transparent window into historical prediction data. Track the algorithm's success over time to uncover valuable model trends. With Line Prophet, informed decisions are not just a possibility but a result of data-driven insights and a commitment to transparency."
                buttons={[
                    <MDBBtn onClick={() => history.push('/packages')} color="primary" size="lg">
                        <MDBIcon className="me-2" icon="sign-in-alt" />
                        Get Started
                    </MDBBtn>,
                ]}
                contentType="vip-stats"
            />
            <Testimonials />
        </LayoutWithHeader>
    );
};

export default About;
