// Library imports
import axios from 'axios';
// Service imports
import { getUserToken } from '../services/Firebase';

const apiUrl = process.env.REACT_APP_HOST;

const request = async (method, endpoint, body = null, headers = {}) => {
    try {
        const token = await getUserToken();
        const response = await axios({
            method,
            url: `${apiUrl}/api/${endpoint}`,
            data: body,
            headers: { ...headers, authorization: `Bearer ${token}` },
        });
        return response.data;
    } catch (error) {
        throw new Error(error.response.data.error);
    }
};

// Helper functions
export const get = async endpoint => {
    return await request('get', endpoint);
};

export const post = async (endpoint, body, headers = {}) => {
    return await request('post', endpoint, body, headers);
};

export const patch = async (endpoint, body) => {
    return await request('patch', endpoint, body);
};

export const httpDelete = async (endpoint, body) => {
    return await request('delete', endpoint, body);
};
