// Library imports
import React, { useState } from 'react';
import moment from 'moment';
import { MDBBtn, MDBIcon, MDBBadge } from 'mdb-react-ui-kit';
import { useDispatch, useSelector } from 'react-redux';
import { bool, shape, string } from 'prop-types';
// Component imports
import Prediction from './windows/Prediction';
import Schedule from './windows/Schedule';
import InjuryList from './windows/InjuryList';
import Headlines from './windows/Headlines';
import PublicMoneyPercent from './windows/PublicMoneyPercent';
import PickOdds from './windows/PickOdds';
import LineData from './windows/LineData';
import PowerRank from './windows/PowerRank';
import KeyFactors from './windows/KeyFactors';
import Pitchers from './windows/Pitchers';
import Totals from './windows/Totals';
import AdvancedSearch from './windows/AdvancedSearch';
import Situations from './windows/Situations';
import UpgradeTooltip from '../../../../components/UpgradeTooltip';
import ToolTip from './windows/PowerRank/ToolTip';
// Styled components
import {
    BadgeContainer,
    GameCardHeaderWrapper,
    GameCardSubtitle,
    GameCardTitle,
    GameCardTitleWrapper,
    Toolbar,
    GameCardWrapper,
    ToolbarButton,
    GameCardContentWrapper,
    HeaderButtonContainer,
} from './styles';
// Constants
import { WINDOW_DATA } from './constants';
// Action imports
import { setEditGame, setSubPage } from '../../../../store/slices/appSlice';

const GameCard = ({ demo = false, sport, game, defaultWindow = 'power-rank', vipPlay = false, history = false }) => {
    // Redux
    const { user } = useSelector(state => state.user);
    const dispatch = useDispatch();
    // Local state
    const [window, setWindow] = useState(WINDOW_DATA.find(data => data.id === defaultWindow));

    const handleChangeWindow = e => {
        setWindow(WINDOW_DATA.find(data => data.id === e.currentTarget.id));
    };

    const handleEditGame = () => {
        // NOTE games in mongodb do not have a status (completed)
        dispatch(setEditGame({ id: game.gameId, db: game.status ? 'firebase' : 'mongo' }));
        dispatch(setSubPage('edit-game'));
    };

    if (!game) return null;

    return (
        <GameCardWrapper key={game.gameId} id={game.gameId}>
            <GameCardHeaderWrapper>
                <GameCardTitleWrapper>
                    <GameCardTitle>
                        {window.title} {window.id === 'power-rank' && <ToolTip />}
                    </GameCardTitle>
                    <GameCardSubtitle>{game.status === 'complete' ? game.status : moment(new Date(game.dateTime)).format('MM/DD hh:mm A')}</GameCardSubtitle>
                    <BadgeContainer>
                        {game.status === 'live' && (
                            <MDBBadge color="danger" className="me-1">
                                Live
                            </MDBBadge>
                        )}
                        {vipPlay && <MDBBadge color="warning">VIP Play</MDBBadge>}
                    </BadgeContainer>
                </GameCardTitleWrapper>
                <HeaderButtonContainer>
                    <MDBBtn
                        color={window.id === 'pick-odds' ? 'primary' : 'light'}
                        onClick={handleChangeWindow}
                        id="pick-odds"
                        disabled={moment(game.dateTime).isBefore(moment.now()) || demo || user.reciept.type === 'free-account'}
                        /* Collapsed sidebar zIndex is 0, so this button needs to be 1 */
                        style={{ border: `1px solid ${window.id === 'pick-odds' ? '#0d6efd' : '#e9ecef'}`, zIndex: 1 }}
                    >
                        Picks
                    </MDBBtn>
                    {user.admin && (
                        <MDBBtn
                            color="primary"
                            onClick={handleEditGame}
                            id="edit-game"
                            disabled={!user.admin}
                            /* Collapsed sidebar zIndex is 0, so this button needs to be 1 */
                            style={{ border: '1px solid #0d6efd', zIndex: 1 }}
                        >
                            Edit
                        </MDBBtn>
                    )}
                </HeaderButtonContainer>
            </GameCardHeaderWrapper>
            <GameCardContentWrapper>
                {window.id === 'power-rank' && <PowerRank demo={demo} game={game} sport={sport} history={history} />}
                {window.id === 'line-movement' && <LineData demo={demo} game={game} sport={sport} />}
                {window.id === 'percent' && <PublicMoneyPercent game={game} sport={sport} />}
                {window.id === 'prediction' && <Prediction game={game} sport={sport} />}
                {window.id === 'headlines' && <Headlines game={game} sport={sport} />}
                {window.id === 'injuries' && <InjuryList game={game} sport={sport} />}
                {window.id === 'schedule' && <Schedule game={game} sport={sport} />}
                {window.id === 'pick-odds' && <PickOdds key={game.gameId} game={game} sport={sport} />}
                {window.id === 'key-factor' && <KeyFactors game={game} sport={sport} />}
                {window.id === 'pitchers' && <Pitchers game={game} sport={sport} />}
                {window.id === 'totals' && <Totals game={game} sport={sport} />}
                {window.id === 'search' && <AdvancedSearch game={game} sport={sport} demo={demo} />}
                {window.id === 'situations' && <Situations game={game} demo={demo} />}
            </GameCardContentWrapper>
            <Toolbar>
                <ToolbarButton id="power-rank" onClick={handleChangeWindow} active={(window.id === 'power-rank').toString()}>
                    <MDBIcon icon="list-ol" />
                    <span>Power</span>
                </ToolbarButton>
                <ToolbarButton id="line-movement" onClick={handleChangeWindow} active={(window.id === 'line-movement').toString()}>
                    <MDBIcon icon="chart-line" />
                    <span>Lines</span>
                </ToolbarButton>
                <ToolbarButton id="percent" onClick={handleChangeWindow} active={(window.id === 'percent').toString()}>
                    <MDBIcon icon="percentage" />
                    <span>Public</span>
                </ToolbarButton>
                <ToolbarButton id="key-factor" onClick={handleChangeWindow} active={(window.id === 'key-factor').toString()}>
                    <MDBIcon icon="key" />
                    <span>Factors</span>
                </ToolbarButton>
                {sport === 'mlb' && (
                    <ToolbarButton id="pitchers" onClick={handleChangeWindow} active={(window.id === 'pitchers').toString()}>
                        <MDBIcon icon="baseball-ball" />
                        <span>Pitchers</span>
                    </ToolbarButton>
                )}
                <ToolbarButton disabled={history} id="situations" onClick={handleChangeWindow} active={(window.id === 'situations').toString()}>
                    <MDBIcon icon="tools" />
                    <span>Situations</span>
                </ToolbarButton>
                <ToolbarButton disabled={history} id="totals" onClick={handleChangeWindow} active={(window.id === 'totals').toString()}>
                    <MDBIcon icon="equals" />
                    <span>Totals</span>
                </ToolbarButton>
                <ToolbarButton id="injuries" onClick={handleChangeWindow} active={(window.id === 'injuries').toString()}>
                    <MDBIcon icon="file-medical" />
                    <span>Injuries</span>
                </ToolbarButton>
                <ToolbarButton
                    disabled={demo || history ? true : false}
                    id="schedule"
                    onClick={handleChangeWindow}
                    active={(window.id === 'schedule').toString()}
                >
                    <MDBIcon icon="calendar" />
                    <span>Schedules</span>
                </ToolbarButton>
                <ToolbarButton disabled={history} id="search" onClick={handleChangeWindow} active={(window.id === 'search').toString()}>
                    <MDBIcon icon="history" />
                    <span>Adv. Search</span>
                </ToolbarButton>
                {!demo && user.reciept.type === 'free-account' ? (
                    <UpgradeTooltip
                        children={
                            <ToolbarButton id="prediction" disabled={true} onClick={() => ''}>
                                <MDBIcon icon="lock" />
                                <span>Prediction</span>
                            </ToolbarButton>
                        }
                    />
                ) : (
                    <ToolbarButton id="prediction" onClick={handleChangeWindow} active={(window.id === 'prediction').toString()}>
                        <MDBIcon icon="calculator" />
                        <span>Prediction</span>
                    </ToolbarButton>
                )}
                <ToolbarButton disabled={history} id="headlines" onClick={handleChangeWindow} active={(window.id === 'headlines').toString()}>
                    <MDBIcon icon="book-reader" />
                    <span>Headlines</span>
                </ToolbarButton>
            </Toolbar>
        </GameCardWrapper>
    );
};

GameCard.propTypes = {
    demo: bool,
    sport: string.isRequired,
    game: shape({}).isRequired,
    defaultWindow: string,
    vipPlay: bool,
    history: bool,
};

export default GameCard;
